import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Select,
  MenuItem,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
  Skeleton
} from '@mui/material';
import createApi from '../services/api';

const api = createApi();

/**
 * Truncates the event title by removing phrases like "presented by X".
 * @param {string} title - The original event title.
 * @returns {string} The truncated event title.
 */
const truncateEventTitle = (title) => {
  return title.replace(/\s+(presented|sponsored|hosted)\s+by\s+.*/i, '').trim();
};

/**
 * Analytics component for displaying and analyzing events for a specific team and year.
 */
const Analytics = () => {
  const navigate = useNavigate();
  const [teamNumber, setTeamNumber] = useState('2102');
  const [year, setYear] = useState(new Date().getFullYear());
  const [events, setEvents] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [fetchTime, setFetchTime] = useState(null);

  /**
   * Fetches events for the selected team and year.
   */
  const fetchEvents = useCallback(async () => {
    setLoading(true);
    setError('');
    const startTime = performance.now();
    try {
      const fetchedEvents = await api.fetchEventsByTeamAndYear(teamNumber, year);
      const endTime = performance.now();
      setFetchTime(endTime - startTime);
      console.log(`[Analytics] Fetch time: ${endTime - startTime}ms`);
      const sortedEvents = fetchedEvents.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
      setEvents(sortedEvents);
    } catch (err) {
      console.error('[Analytics] Error fetching events:', err);
      setError('Failed to fetch events. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [teamNumber, year]);

  useEffect(() => {
    if (!initialFetchDone) {
      fetchEvents();
      setInitialFetchDone(true);
    }
  }, [fetchEvents, initialFetchDone]);

  const handleTeamNumberChange = (event) => {
    const value = event.target.value;
    if (value === '' || (Number(value) >= 0 && Number(value) <= 9999)) {
      setTeamNumber(value);
    }
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleEventSelect = (eventKey) => {
    navigate(`/event-analytics/${eventKey}`);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  };

  const getEventWeek = (event) => {
    if (event.week !== null && event.week !== undefined) {
      return `Week ${event.week + 1}`; // Add 1 because TBA uses 0-indexed weeks
    } else if (event.event_type === 99) {
      return "Offseason";
    } else {
      return ""; // Return empty string for other event types
    }
  };

  const isCurrentEvent = (event) => {
    const now = new Date();
    const startDate = new Date(event.start_date);
    const endDate = new Date(event.end_date);
    return now >= startDate && now <= endDate;
  };

  const renderEventList = () => {
    if (loading) {
      return (
        <Box my={4}>
          <Typography variant="h6" gutterBottom>
            <Skeleton width="40%" />
          </Typography>
          <Paper elevation={3}>
            <List>
              {[...Array(5)].map((_, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={<Skeleton width="60%" />}
                    secondary={<Skeleton width="40%" />}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Box>
      );
    }

    if (events.length === 0) {
      return (
        <Alert severity="info">No events found. Please fetch events using the button above.</Alert>
      );
    }

    return (
      <Box my={4}>
        <Typography variant="h6" gutterBottom>
          Select an Event
        </Typography>
        <Paper elevation={3}>
          <List>
            {events.map((event) => {
              const current = isCurrentEvent(event);
              return (
                <ListItem 
                  key={event.key} 
                  button 
                  onClick={() => handleEventSelect(event.key)}
                  sx={{
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    backgroundColor: current ? 'rgba(0, 0, 0, 0.04)' : 'inherit',
                  }}
                >
                  <ListItemText 
                    primary={truncateEventTitle(event.name)} 
                    secondary={`${formatDate(event.start_date)} - ${formatDate(event.end_date)}${getEventWeek(event) ? ` | ${getEventWeek(event)}` : ''}`}
                    primaryTypographyProps={{ 
                      variant: 'subtitle1',
                      fontWeight: current ? 'bold' : 'normal',
                    }}
                    secondaryTypographyProps={{ variant: 'body2' }}
                  />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </Box>
    );
  };

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Analytics
        </Typography>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Team Number"
              value={teamNumber}
              onChange={handleTeamNumberChange}
              variant="outlined"
              inputProps={{
                maxLength: 4,
                inputMode: 'numeric',
                pattern: '[0-9]*'
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Select
              fullWidth
              value={year}
              onChange={handleYearChange}
              variant="outlined"
            >
              {[...Array(5)].map((_, i) => (
                <MenuItem key={i} value={new Date().getFullYear() - i}>
                  {new Date().getFullYear() - i}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button 
              fullWidth
              variant="contained" 
              onClick={fetchEvents}
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Fetch Events'}
            </Button>
          </Grid>
        </Grid>
        {fetchTime && (
          <Typography variant="body2" color="textSecondary" mt={2}>
            Fetch time: {fetchTime.toFixed(2)}ms
          </Typography>
        )}
      </Box>
      
      {renderEventList()}

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Analytics;