import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

/**
 * Footer component that displays at the bottom of every page
 * @returns {React.Component} Footer component
 */
const Footer = () => {
  return (
    <Box component="footer" sx={{ mt: 'auto', py: 2, textAlign: 'center' }}>
      <Typography variant="body2" color="text.secondary">
        © {new Date().getFullYear()} RoboRecon2 | 
        <Link to="/privacy-policy" style={{ textDecoration: 'none' }}>
          <Typography component="span" sx={{ ml: 1, cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }} color="text.secondary">
            Privacy Policy
          </Typography>
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;