'use server';

import { getApi } from '../../services/api';

/**
 * Server action to update user settings
 * @param {Object} updatedSettings - The updated user settings
 * @returns {Promise<Object>} The result of the update operation
 */
export async function updateUserSettings(updatedSettings) {
  try {
    const api = getApi();
    const response = await api.post('/api/user/settings', updatedSettings);
    
    if (response.success) {
      return { success: true, user: response.user };
    } else {
      return { success: false, error: response.error };
    }
  } catch (error) {
    console.error('Error updating user settings:', error);
    return { success: false, error: 'An error occurred while updating settings.' };
  }
}

/**
 * Server action to fetch user settings
 * @returns {Promise<Object>} The user settings
 */
export async function fetchUserSettings() {
  try {
    const api = getApi();
    const response = await api.get('/api/user/settings');
    
    if (response.success) {
      return { success: true, settings: response.settings };
    } else {
      return { success: false, error: response.error };
    }
  } catch (error) {
    console.error('Error fetching user settings:', error);
    return { success: false, error: 'An error occurred while fetching settings.' };
  }
}