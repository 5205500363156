'use client';

import React from 'react';
import { Typography, Container, Box } from '@mui/material';

/**
 * PrivacyPolicy component displays the privacy policy for RoboRecon2.
 * It renders the policy content using Material-UI components for consistent styling.
 */
const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h2" component="h1" gutterBottom>
          RoboRecon2 Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Last updated: Oct 1, 2024
        </Typography>

        <Typography variant="h4" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          RoboRecon2 ("we", "our", or "us") is committed to protecting the privacy of our users ("you" or "your"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our FIRST Robotics FRC Scouting Application ("the App").
        </Typography>
        <Typography variant="body1" paragraph>
          Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the App.
        </Typography>

        <Typography variant="h4" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect information that you provide directly to us when using the App:
        </Typography>
        <Typography component="ul">
          <li>Personal Information: This may include your name, email address, and other contact information.</li>
          <li>User Account Information: Information related to your account such as username (password is processed through Google OAuth and we never know your password).</li>
          <li>Team and Event Data: Information about FIRST Robotics teams and events that you input or access through the App, including team numbers, event codes, and competition schedules.</li>
          <li>Scouting Data: Information collected about robot performance and capabilities during FIRST Robotics competitions, including but not limited to scoring data, autonomous capabilities, and strategy observations.</li>
        </Typography>

        {/* Continue with the rest of the sections... */}

        <Typography variant="h4" gutterBottom>
          11. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy or the practices of RoboRecon2, please contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          Email: nickfoote@team2102.org
          <br />
          Website: https://team2102.org
        </Typography>
        <Typography variant="body1" paragraph>
          By using RoboRecon2, you agree to the collection and use of information in accordance with this policy.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;