import React, { useState, useContext, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { Typography, Box, CircularProgress, Alert, useTheme, Paper, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getApi } from '../services/api';
import { AuthContext } from '../contexts/AuthContext';

/**
 * Login component for Google authentication
 * @returns {JSX.Element} Login component
 */
const Login = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [debugInfo, setDebugInfo] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    console.log('[Login] Component mounted');
    console.log('[Login] REACT_APP_GOOGLE_CLIENT_ID:', process.env.REACT_APP_GOOGLE_CLIENT_ID);
    console.log('[Login] REACT_APP_GOOGLE_OAUTH_SCOPES:', process.env.REACT_APP_GOOGLE_OAUTH_SCOPES);
    console.log('[Login] REACT_APP_API_URL:', process.env.REACT_APP_API_URL);
    console.log('[Login] Current environment:', process.env.NODE_ENV);
  }, []);

  /**
   * Handles successful Google login
   * @param {Object} credentialResponse - Response from Google login
   */
  const handleGoogleLoginSuccess = async (credentialResponse) => {
    console.log('[Login] Google login success', credentialResponse);
    setIsLoading(true);
    setError('');
    setDebugInfo(null);
    try {
      const api = getApi();
      console.log('[Login] Authenticating with Google...');
      console.log('[Login] Credential:', credentialResponse.credential);
      
      const response = await api.authenticateWithGoogle(credentialResponse.credential);
      console.log('[Login] Authentication response', response);
      
      if (response.success) {
        console.log('[Login] Login successful, navigating to home');
        login(response.token, response.refreshToken, response.user);
        navigate('/');
      } else {
        console.log('[Login] Authentication failed');
        setError('Authentication failed. Please try again.');
        setDebugInfo(response);
      }
    } catch (error) {
      console.error('[Login] Error during authentication:', error);
      let errorMessage = 'An unknown error occurred';
      let debugData = {};

      if (error.response) {
        console.error('[Login] Error response:', error.response);
        console.error('[Login] Error response status:', error.response.status);
        console.error('[Login] Error response data:', error.response.data);
        console.error('[Login] Error response headers:', error.response.headers);
        errorMessage = `Authentication failed: ${error.response.data?.message || 'Server error'}`;
        debugData = {
          status: error.response.status,
          data: error.response.data,
          headers: error.response.headers,
        };
      } else if (error.request) {
        console.error('[Login] Error request:', error.request);
        errorMessage = 'Network error. Please check your internet connection and try again.';
        debugData = { request: error.request };
      } else {
        console.error('[Login] Error message:', error.message);
        errorMessage = `An error occurred: ${error.message}`;
      }

      if (error.code === 'ECONNABORTED') {
        errorMessage = 'The request timed out. Please try again.';
      }

      setError(errorMessage);
      setDebugInfo(debugData);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handles Google login error
   */
  const handleGoogleLoginError = (error) => {
    console.error('[Login] Google login error', error);
    setError('Google login failed. Please try again.');
    setDebugInfo(error);
  };

  /**
   * Displays debug information
   */
  const renderDebugInfo = () => {
    if (!debugInfo) return null;
    return (
      <Box mt={2}>
        <Typography variant="h6">Debug Information:</Typography>
        <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {JSON.stringify(debugInfo, null, 2)}
        </pre>
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor={theme.palette.background.default}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 600,
          width: '100%',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome to RoboRecon2
        </Typography>
        <Typography variant="body1" align="center" gutterBottom sx={{ mb: 2 }}>
          RoboRecon2 is a Match scouting system for FRC Robotics Competitions.
          It allows for the input of robot performance in each match and
          provides Data Analytics to help pick the best alliance mate for playoffs.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Please sign in with your Google account in order to submit or view data
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mt: 2, mb: 2, width: '100%' }}>
            {error}
          </Alert>
        )}
        {isLoading ? (
          <CircularProgress sx={{ mt: 2 }} />
        ) : (
          <Box mt={3}>
            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={handleGoogleLoginError}
              useOneTap
              theme="filled_blue"
              shape="pill"
              size="large"
              text="signin_with"
              locale="en"
            />
          </Box>
        )}
        {renderDebugInfo()}
        {debugInfo && (
          <Button
            variant="outlined"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => console.log('[Login] Debug Info:', debugInfo)}
          >
            Log Debug Info to Console
          </Button>
        )}
      </Paper>
    </Box>
  );
};

export default Login;