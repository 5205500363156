import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography, CircularProgress } from '@mui/material';
import PracticeMatchConfig from './PracticeMatchConfig';
import PracticeMatchInput from './PracticeMatchInput';
import PracticeMatchList from './PracticeMatchList';
import PracticeMatchGraph from './PracticeMatchGraph';
import PracticeMatchPreview from './PracticeMatchPreview';
import createApi from '../services/api';

function PracticeMatchPage({ apiUrl }) {
  const [tabValue, setTabValue] = useState(0);
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const api = createApi(apiUrl);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setLoading(true);
        // First, try to get the saved practice match config
        const savedConfig = await api.getPracticeMatchConfig();
        if (savedConfig) {
          setConfig(savedConfig);
        } else {
          // If no saved config, fall back to the current year's scouting config
          const year = new Date().getFullYear();
          const response = await api.getScoutingConfig(year);
          setConfig(response.sections);
        }
      } catch (error) {
        console.error('Error fetching configuration:', error);
        // In case of error, set an empty config
        setConfig([]);
      } finally {
        setLoading(false);
      }
    };

    fetchConfig();
  }, [api]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSelectMatch = (match) => {
    setSelectedMatch(match);
  };

  const handleClosePreview = () => {
    setSelectedMatch(null);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        Practice Match Data
      </Typography>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Input" />
        <Tab label="View" />
        <Tab label="Graph" />
        <Tab label="Configure" />
      </Tabs>
      <Box sx={{ p: 3 }}>
        {tabValue === 3 && <PracticeMatchConfig config={config} setConfig={setConfig} api={api} />}
        {tabValue === 0 && <PracticeMatchInput config={config} api={api} />}
        {tabValue === 1 && (
          <Box>
            <PracticeMatchList api={api} onSelectMatch={handleSelectMatch} />
            <PracticeMatchPreview match={selectedMatch} onClose={handleClosePreview} />
          </Box>
        )}
        {tabValue === 2 && <PracticeMatchGraph api={api} />}
      </Box>
    </Box>
  );
}

export default PracticeMatchPage;