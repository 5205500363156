import React, { useState, useContext } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Avatar, Menu, MenuItem, IconButton, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

/**
 * UserMenu component for displaying the user avatar and dropdown menu.
 * @returns {React.Component} UserMenu component
 */
const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
    handleClose();
  };

  /**
   * Function to get the user's initials as a fallback
   * @param {string} name - The user's name
   * @returns {string} The user's initials
   */
  const getInitials = (name) => {
    return name
      .split(' ')
      .map(part => part[0])
      .join('')
      .toUpperCase();
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
        <Avatar
          sx={{ width: 32, height: 32 }}
          src={user?.googleProfileImage}
          alt={user?.name}
        >
          {!user?.googleProfileImage && getInitials(user?.name || 'U')}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem component={RouterLink} to="/user-settings">User Settings</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

/**
 * MobileMenu component for displaying the hamburger menu on mobile devices.
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Whether the menu is open
 * @param {Function} props.onClose - Function to close the menu
 * @param {HTMLElement} props.anchorEl - The anchor element for the menu
 * @returns {React.Component} MobileMenu component
 */
const MobileMenu = ({ isOpen, onClose, anchorEl }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      onClick={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem component={RouterLink} to="/">Scout</MenuItem>
      <MenuItem component={RouterLink} to="/scouting-config">Scout Config</MenuItem>
      <MenuItem component={RouterLink} to="/practice-match">Practice Match</MenuItem>
      <MenuItem component={RouterLink} to="/analytics">Analytics</MenuItem>
    </Menu>
  );
};

/**
 * TopMenu component for displaying the main navigation menu.
 * Responsive design: full menu on desktop, hamburger menu on mobile.
 * @returns {React.Component} TopMenu component
 */
const TopMenu = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);

  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          RoboRecon2
        </Typography>
        {isMobile ? (
          <>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <MobileMenu
              isOpen={Boolean(mobileMenuAnchorEl)}
              onClose={handleMobileMenuClose}
              anchorEl={mobileMenuAnchorEl}
            />
          </>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button color="inherit" component={RouterLink} to="/">
              Scout
            </Button>
            <Button color="inherit" component={RouterLink} to="/scouting-config">
              Scout Config
            </Button>
            <Button color="inherit" component={RouterLink} to="/practice-match">
              Practice Match
            </Button>
            <Button color="inherit" component={RouterLink} to="/analytics">
              Analytics
            </Button>
          </Box>
        )}
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};

export default TopMenu;