import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Paper } from '@mui/material';

const ElimsBracket = ({ matches }) => {
  const [bracketMatches, setBracketMatches] = useState({
    round1: [],
    round2: [],
    round3: [],
    round4: [],
    round5: [],
    finals: []
  });
  const bracketRef = useRef(null);

  useEffect(() => {
    const organizeMatches = () => {
      const sortedMatches = [...matches].sort((a, b) => {
        if (a.comp_level !== b.comp_level) {
          return ['qf', 'sf', 'f'].indexOf(a.comp_level) - ['qf', 'sf', 'f'].indexOf(b.comp_level);
        }
        return a.match_number - b.match_number;
      });

      const organized = {
        round1: sortedMatches.slice(0, 4),
        round2: sortedMatches.slice(4, 8),
        round3: sortedMatches.slice(8, 10),
        round4: sortedMatches.slice(10, 12),
        round5: sortedMatches.slice(12, 13),
        finals: sortedMatches.filter(m => m.comp_level === 'f').slice(0, 3)
      };

      setBracketMatches(organized);
    };

    organizeMatches();
  }, [matches]);

  const renderMatch = (match, matchNumber) => {
    if (!match) return null;

    const redWon = match.winning_alliance === 'red';
    const blueWon = match.winning_alliance === 'blue';

    const renderAlliance = (alliance, allianceNumber, won) => (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: won ? (allianceNumber === 1 ? '#ff0000' : '#0000ff') : 'transparent',
        color: won ? 'white' : '#4fc3f7',
        p: 0.5
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ width: '10%' }}>{allianceNumber}</Typography>
          <Typography variant="body2" sx={{ width: '70%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {alliance.team_keys.map(team => team.replace('frc', '')).join(', ')}
          </Typography>
          <Typography variant="body2" sx={{ width: '20%', textAlign: 'right' }}>{alliance.score}</Typography>
        </Box>
      </Box>
    );

    return (
      <Paper
        key={match.key}
        elevation={3}
        sx={{
          width: '220px',
          m: 0.5,
          overflow: 'hidden',
          border: '1px solid #4fc3f7',
          borderRadius: '4px',
          bgcolor: 'transparent',
          zIndex: 1
        }}
      >
        <Typography variant="caption" sx={{ bgcolor: '#4fc3f7', color: '#000', p: 0.5, display: 'block' }}>
          Match {matchNumber}
        </Typography>
        {renderAlliance(match.alliances.red, 1, redWon)}
        {renderAlliance(match.alliances.blue, 2, blueWon)}
      </Paper>
    );
  };

  const renderBracket = () => {
    return (
      <Box ref={bracketRef} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', position: 'relative', p: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          {bracketMatches.round1.map((match, index) => renderMatch(match, index + 1))}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8, mt: 8 }}>
          {bracketMatches.round2.map((match, index) => renderMatch(match, index + 5))}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 16, mt: 16 }}>
          {bracketMatches.round3.map((match, index) => renderMatch(match, index + 9))}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 32, mt: 32 }}>
          {bracketMatches.round4.map((match, index) => renderMatch(match, index + 11))}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 64, mt: 64 }}>
          {bracketMatches.round5.map((match, index) => renderMatch(match, index + 13))}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, mt: 96 }}>
          {bracketMatches.finals.map((match, index) => renderMatch(match, `F${index + 1}`))}
        </Box>
        {renderConnectionLines()}
      </Box>
    );
  };

  const renderConnectionLines = () => {
    if (!bracketRef.current) return null;

    const bracketRect = bracketRef.current.getBoundingClientRect();
    const matchBoxes = Array.from(bracketRef.current.querySelectorAll('div > div > .MuiPaper-root'));
    
    const getBoxCenter = (box) => {
      if (!box) return null;
      const rect = box.getBoundingClientRect();
      return {
        x: rect.left + rect.width - bracketRect.left,
        y: rect.top + rect.height / 2 - bracketRect.top
      };
    };

    const lines = [];

    const connectMatches = (startIndex, endIndex) => {
      const start = getBoxCenter(matchBoxes[startIndex]);
      const end = getBoxCenter(matchBoxes[endIndex]);
      if (start && end) {
        lines.push(`M${start.x},${start.y} H${(start.x + end.x) / 2} V${end.y} H${end.x}`);
      }
    };

    // Connect Round 1 to Round 2
    connectMatches(0, 4);
    connectMatches(1, 4);
    connectMatches(2, 5);
    connectMatches(3, 5);

    // Connect Round 2 to Round 3
    connectMatches(4, 8);
    connectMatches(5, 8);
    connectMatches(6, 9);
    connectMatches(7, 9);

    // Connect Round 3 to Round 4
    connectMatches(8, 10);
    connectMatches(9, 11);

    // Connect Round 4 to Round 5
    connectMatches(10, 12);
    connectMatches(11, 12);

    // Connect Round 5 to Finals
    if (matchBoxes[13]) {
      connectMatches(12, 13);
    }

    return (
      <svg style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none', zIndex: 0 }}>
        {lines.map((d, i) => (
          <path key={i} d={d} fill="none" stroke="#4fc3f7" strokeWidth="2" />
        ))}
      </svg>
    );
  };

  return (
    <Box sx={{ width: '100%', overflowX: 'auto', color: 'white' }}>
      <Typography variant="h5" gutterBottom sx={{ p: 2 }}>Elimination Bracket</Typography>
      {renderBracket()}
    </Box>
  );
};

export default ElimsBracket;