import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  Grid,
  Paper,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
  Rating,
  ButtonGroup,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import BlockIcon from '@mui/icons-material/Block';

const ColoredButton = styled(Button)(({ theme, color }) => ({
  backgroundColor: theme.palette[color].main,
  color: theme.palette[color].contrastText,
  '&:hover': {
    backgroundColor: theme.palette[color].dark,
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme, selected, color }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette[color || 'primary'].main,
    color: theme.palette[color || 'primary'].contrastText,
    '&:hover': {
      backgroundColor: theme.palette[color || 'primary'].dark,
    },
  },
}));

/**
 * CounterInput component for rendering counter type inputs
 * @param {Object} props - Component props
 * @param {Object} props.value - Current value of the counter
 * @param {Function} props.onChange - Function to handle value changes
 * @param {boolean} props.dual - Whether to show two counters (made/missed) or just one
 */
const CounterInput = ({ value = {}, onChange, dual = true }) => {
  const renderCounter = (key, color) => (
    <ButtonGroup variant="contained" size="small">
      <ColoredButton color="error" onClick={() => onChange({ ...value, [key]: Math.max(0, (value[key] || 0) - 1) })}>
        <RemoveIcon />
      </ColoredButton>
      <ColoredButton color={color} disabled style={{ opacity: 1, width: '60px', backgroundColor: key === 'made' ? 'green' : 'red' }}>
        <Typography variant="h5" style={{ fontWeight: 'bold', color: 'white' }}>
          {value[key] || 0}
        </Typography>
      </ColoredButton>
      <ColoredButton color="success" onClick={() => onChange({ ...value, [key]: (value[key] || 0) + 1 })}>
        <AddIcon />
      </ColoredButton>
    </ButtonGroup>
  );

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {dual ? (
        <>
          <Box mr={2}>
            <Typography variant="caption" display="block" textAlign="center">Made</Typography>
            {renderCounter('made', 'success')}
          </Box>
          <Box>
            <Typography variant="caption" display="block" textAlign="center">Missed</Typography>
            {renderCounter('missed', 'error')}
          </Box>
        </>
      ) : (
        renderCounter('value', 'primary')
      )}
    </Box>
  );
};

/**
 * YesNoButton component for rendering boolean type inputs
 * @param {Object} props - Component props
 * @param {boolean} props.value - Current value of the boolean
 * @param {Function} props.onChange - Function to handle value changes
 * @param {string} props.label - Label for the button
 */
const YesNoButton = ({ value, onChange, label }) => (
  <Button
    variant="contained"
    onClick={() => onChange(!value)}
    startIcon={value ? <CheckIcon /> : <CloseIcon />}
    sx={{
      backgroundColor: value ? 'success.main' : 'error.main',
      '&:hover': {
        backgroundColor: value ? 'success.dark' : 'error.dark',
      },
    }}
  >
    {label}
  </Button>
);

/**
 * TimerButton component for rendering timer type inputs
 * @param {Object} props - Component props
 * @param {number} props.value - Current value of the timer in seconds
 * @param {Function} props.onChange - Function to handle value changes
 * @param {string} props.label - Label for the button
 */
const TimerButton = ({ value, onChange, label }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        const elapsedSeconds = Math.floor((Date.now() - startTime) / 1000);
        onChange(elapsedSeconds);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning, startTime, onChange]);

  const toggleTimer = () => {
    if (isRunning) {
      setIsRunning(false);
    } else {
      setIsRunning(true);
      setStartTime(Date.now() - value * 1000);
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <Button
      variant="contained"
      onClick={toggleTimer}
      sx={{
        backgroundColor: isRunning ? 'error.light' : 'info.light',
        '&:hover': {
          backgroundColor: isRunning ? 'error.main' : 'info.main',
        },
        textTransform: 'none',
      }}
    >
      {label}: {formatTime(value)}
    </Button>
  );
};

/**
 * AttemptedInput component for rendering attempted type inputs
 * @param {Object} props - Component props
 * @param {string} props.value - Current value of the attempted input
 * @param {Function} props.onChange - Function to handle value changes
 */
const AttemptedInput = ({ value, onChange }) => (
  <ToggleButtonGroup
    value={value}
    exclusive
    onChange={(_, newValue) => onChange(newValue)}
    aria-label="attempted"
  >
    <Tooltip title="Succeeded" arrow>
      <StyledToggleButton value="succeeded" aria-label="succeeded" color="success">
        <CheckCircleIcon sx={{ fontSize: 40 }} />
      </StyledToggleButton>
    </Tooltip>
    <Tooltip title="Attempted but Failed" arrow>
      <StyledToggleButton value="failed" aria-label="failed" color="error">
        <CancelIcon sx={{ fontSize: 40 }} />
      </StyledToggleButton>
    </Tooltip>
    <Tooltip title="Didn't Attempt" arrow>
      <StyledToggleButton value="not_attempted" aria-label="not attempted" color="warning">
        <BlockIcon sx={{ fontSize: 40 }} />
      </StyledToggleButton>
    </Tooltip>
  </ToggleButtonGroup>
);

/**
 * MetricInput component for rendering different types of input fields
 * @param {Object} props - Component props
 * @param {Object} props.metric - Metric object containing type and other properties
 * @param {*} props.value - Current value of the metric
 * @param {Function} props.onChange - Function to handle value changes
 */
const MetricInput = ({ metric, value, onChange }) => {
  switch (metric.type) {
    case 'counter':
      return (
        <CounterInput
          value={value || { made: 0, missed: 0 }}
          onChange={onChange}
          dual={true}
        />
      );
    case 'number':
      return (
        <CounterInput
          value={{ value: value || 0 }}
          onChange={(newValue) => onChange(newValue.value)}
          dual={false}
        />
      );
    case 'boolean':
      return (
        <YesNoButton
          value={value || false}
          onChange={onChange}
          label={metric.name}
        />
      );
    case 'ranking':
      return (
        <Rating
          name={metric.name}
          value={value || 0}
          onChange={(_, newValue) => onChange(newValue)}
          max={metric.max || 5}
          sx={{ fontSize: 40 }}
        />
      );
    case 'range':
      return (
        <Box sx={{ width: '80%', margin: '0 auto' }}>
          <Slider
            value={value || metric.min}
            onChange={(_, newValue) => onChange(newValue)}
            valueLabelDisplay="auto"
            min={metric.min}
            max={metric.max}
            step={(metric.max - metric.min) / 20}
            marks={[
              { value: metric.min, label: metric.min.toString() },
              { value: metric.max, label: metric.max.toString() },
            ]}
          />
        </Box>
      );
    case 'multipleChoice':
      return (
        <ToggleButtonGroup
          value={value || ''}
          exclusive
          onChange={(_, newValue) => onChange(newValue)}
          aria-label={metric.name}
        >
          {metric.options.map((option) => (
            <StyledToggleButton key={option} value={option} aria-label={option} color="info">
              {option}
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>
      );
    case 'attempted':
      return (
        <AttemptedInput
          value={value || 'not_attempted'}
          onChange={onChange}
        />
      );
    case 'timer':
      return (
        <TimerButton
          value={value || 0}
          onChange={onChange}
          label={metric.name}
        />
      );
    case 'text':
      return (
        <TextField
          label={metric.name}
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
          multiline
          rows={4}
          fullWidth
        />
      );
    default:
      return (
        <TextField
          label={metric.name}
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
        />
      );
  }
};

/**
 * ScoutingFormSection component for displaying a section of the scouting form
 * @param {Object} props - Component props
 * @param {string} props.title - Title of the section
 * @param {Array} props.metrics - Array of metrics for this section
 * @param {Object} props.formData - Current form data
 * @param {Function} props.handleInputChange - Function to handle input changes
 */
const ScoutingFormSection = ({ title, metrics, formData, handleInputChange }) => (
  <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
    <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 'bold', mb: 2 }}>{title}</Typography>
    {metrics.map((metric) => (
      <Box key={metric.name} sx={{ my: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {metric.type !== 'boolean' && metric.type !== 'timer' && (
          <Typography variant="subtitle1" align="center">{metric.name}</Typography>
        )}
        <MetricInput
          metric={metric}
          value={formData[metric.name]}
          onChange={(value) => handleInputChange(metric.name, value)}
        />
      </Box>
    ))}
  </Paper>
);

/**
 * PracticeMatchInput component for displaying and managing practice match data input
 * @param {Object} props - Component props
 * @param {Object} props.api - API object for making server requests
 */
function PracticeMatchInput({ api }) {
  const [config, setConfig] = useState(null);
  const [formData, setFormData] = useState({});
  const [timeLeft, setTimeLeft] = useState(135);
  const [isRunning, setIsRunning] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('inherit');
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const currentYear = new Date().getFullYear().toString();
        const response = await api.getPracticeMatchConfig(currentYear);
        setConfig(response.sections);
        setLoading(false);
      } catch (error) {
        console.error('Error loading practice match configuration:', error);
        setLoading(false);
        setSnackbar({ open: true, message: 'Error loading configuration', severity: 'error' });
      }
    };
    loadConfig();
  }, [api]);

  useEffect(() => {
    let timer;
    if (isRunning && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setBackgroundColor('yellow');
      setTimeout(() => setBackgroundColor('inherit'), 500);
    }
    return () => clearInterval(timer);
  }, [isRunning, timeLeft]);

  const handleStartTimer = () => {
    setIsRunning(true);
    setTimeLeft(135);
  };

  const handleInputChange = useCallback((metricName, value) => {
    setFormData(prevData => ({
      ...prevData,
      [metricName]: value
    }));
  }, []);

  const handleSubmit = async () => {
    try {
      const cleanedFormData = Object.fromEntries(
        Object.entries(formData).filter(([_, value]) => value !== null && value !== undefined && value !== '')
      );
      const pstTimestamp = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Los_Angeles',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      }).format(new Date());
      const response = await api.submitPracticeMatchData({
        data: cleanedFormData,
        timestamp: pstTimestamp
      });
      console.log('Practice match data submitted successfully:', response);
      setFormData({});
      setSnackbar({ open: true, message: 'Practice match data submitted successfully', severity: 'success' });
    } catch (error) {
      console.error('Error submitting practice match data:', error);
      console.error('Error details:', error.response ? error.response.data : 'No response data');
      setSnackbar({ open: true, message: 'Error submitting practice match data', severity: 'error' });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!config) {
    return <Typography>No configuration available</Typography>;
  }

  return (
    <Box sx={{ flexGrow: 1, p: 2, backgroundColor }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', mb: 3 }}>Practice Match Input</Typography>
      <Box mb={2} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h6" mr={2}>Time Left: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}</Typography>
        <Button variant="contained" color={isRunning ? "secondary" : "primary"} onClick={handleStartTimer}>
          {isRunning ? "Reset Timer" : "Start Timer"}
        </Button>
      </Box>
      <Grid container spacing={2}>
        {config.map((section, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ScoutingFormSection
              title={section.name}
              metrics={section.metrics}
              formData={formData}
              handleInputChange={handleInputChange}
            />
          </Grid>
        ))}
      </Grid>
      <Box mt={2} display="flex" justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit Practice Match Data
        </Button>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default PracticeMatchInput;