import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemSecondaryAction,
  Switch,
  Snackbar,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

const metricTypes = [
  { value: 'number', label: 'Number' },
  { value: 'counter', label: 'Made/Missed Counter' },
  { value: 'ranking', label: 'Ranking (1-5)' },
  { value: 'boolean', label: 'Yes/No' },
  { value: 'text', label: 'Text Field' },
  { value: 'range', label: 'Range' },
  { value: 'multipleChoice', label: 'Multiple Choice' },
  { value: 'attempted', label: 'Attempted' },
  { value: 'timer', label: 'Timer' },
];

function PracticeMatchConfig({ api }) {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [availableYears, setAvailableYears] = useState([]);

  const loadConfig = useCallback(async (selectedYear) => {
    setLoading(true);
    try {
      const response = await api.getScoutingConfig(selectedYear);
      if (response && response.sections && Array.isArray(response.sections)) {
        setConfig(response.sections.map(section => ({
          ...section,
          metrics: section.metrics.map(metric => ({
            ...metric,
            visible: true
          }))
        })));
      } else {
        console.warn('Invalid config data received:', response);
        setConfig([]);
      }
    } catch (error) {
      console.error('Error loading configuration:', error);
      setConfig([]);
      setSnackbar({ open: true, message: 'Error loading configuration', severity: 'error' });
    } finally {
      setLoading(false);
    }
  }, [api]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({length: 5}, (_, i) => currentYear - i);
    setAvailableYears(years);
    loadConfig(currentYear.toString());
  }, [loadConfig]);

  const handleVisibilityToggle = (sectionIndex, metricIndex) => {
    setConfig(prevConfig => {
      const newConfig = JSON.parse(JSON.stringify(prevConfig)); // Deep copy
      newConfig[sectionIndex].metrics[metricIndex].visible = !newConfig[sectionIndex].metrics[metricIndex].visible;
      return newConfig;
    });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const practiceMatchConfig = config.map(section => ({
        name: section.name,
        metrics: section.metrics.filter(metric => metric.visible)
      }));
      await api.savePracticeMatchConfig({ sections: practiceMatchConfig });
      setSnackbar({ open: true, message: 'Configuration saved successfully', severity: 'success' });
    } catch (error) {
      console.error('Error saving configuration:', error);
      setSnackbar({ open: true, message: 'Error saving configuration', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setYear(selectedYear);
    loadConfig(selectedYear);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!config) {
    return <Typography>Loading configuration...</Typography>;
  }

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Practice Match Configuration
      </Typography>
      
      <FormControl fullWidth margin="normal">
        <InputLabel>Year</InputLabel>
        <Select
          value={year}
          label="Year"
          onChange={handleYearChange}
        >
          {availableYears.map((y) => (
            <MenuItem key={y} value={y.toString()}>{y}</MenuItem>
          ))}
        </Select>
      </FormControl>

      {config.map((section, sectionIndex) => (
        <Box key={sectionIndex} mt={3}>
          <Typography variant="h6">{section.name}</Typography>
          <List>
            {section.metrics.map((metric, metricIndex) => (
              <ListItem key={metricIndex}>
                <ListItemText 
                  primary={metric.name} 
                  secondary={`Type: ${metricTypes.find(t => t.value === metric.type)?.label}${
                    metric.type === 'range' ? ` (${metric.min} - ${metric.max})` : ''
                  }${
                    metric.type === 'multipleChoice' ? ` (${metric.options.join(', ')})` : ''
                  }${
                    metric.type === 'attempted' ? ` (Succeeded, Attempted but Failed, Didn't Attempt)` : ''
                  }${
                    metric.type === 'timer' ? ` (Max: ${metric.maxTime}s, Idle: ${metric.buttonColors.idle}, Running: ${metric.buttonColors.running})` : ''
                  }`}
                />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={() => handleVisibilityToggle(sectionIndex, metricIndex)}
                    checked={metric.visible}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      ))}

      <Box mt={3}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save Practice Match Configuration
        </Button>
      </Box>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default PracticeMatchConfig;