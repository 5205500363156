import React from 'react';
import { Typography, Container, Box } from '@mui/material';

/**
 * TermsOfService component displays the terms of service for RoboRecon2.
 * It renders the terms content using Material-UI components for consistent styling.
 */
const TermsOfService = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h2" component="h1" gutterBottom>
          RoboRecon2 Terms of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Last updated: Oct 1, 2024
        </Typography>

        <Typography variant="h4" gutterBottom>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing or using RoboRecon2 ("the App"), you agree to comply with and be bound by these Terms of Service. If you do not agree to these terms, please do not use the App.
        </Typography>

        <Typography variant="h4" gutterBottom>
          2. Description of Service
        </Typography>
        <Typography variant="body1" paragraph>
          RoboRecon2 is a FIRST Robotics FRC Scouting Application designed to assist teams in collecting and analyzing data during FIRST Robotics competitions.
        </Typography>

        <Typography variant="h4" gutterBottom>
          3. FIRST Robotics Competition Specific Terms
        </Typography>
        <Typography variant="body1" paragraph>
          3.1. The App is designed for use in conjunction with FIRST Robotics Competitions (FRC). Users agree to abide by all FIRST Robotics Competition rules and regulations while using the App.
        </Typography>
        <Typography variant="body1" paragraph>
          3.2. Users are responsible for ensuring that their use of the App complies with all FIRST Robotics Competition guidelines regarding scouting and data collection.
        </Typography>
        <Typography variant="body1" paragraph>
          3.3. The App is not officially affiliated with or endorsed by FIRST Robotics Competition.
        </Typography>

        <Typography variant="h4" gutterBottom>
          4. User Responsibilities
        </Typography>
        <Typography variant="body1" paragraph>
          4.1. You are responsible for maintaining the confidentiality of your account and password.
        </Typography>
        <Typography variant="body1" paragraph>
          4.2. You agree to use the App in a manner consistent with any and all applicable laws and regulations.
        </Typography>

        <Typography variant="h4" gutterBottom>
          5. Data Usage and Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          5.1. Your use of the App is also governed by our Privacy Policy.
        </Typography>
        <Typography variant="body1" paragraph>
          5.2. You retain all rights to any data you input into the App.
        </Typography>

        <Typography variant="h4" gutterBottom>
          6. Limitations of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          6.1. The App is provided "as is" without any guarantees or warranty.
        </Typography>
        <Typography variant="body1" paragraph>
          6.2. In no event shall RoboRecon2 be liable for any damages resulting from the use or inability to use the App.
        </Typography>

        <Typography variant="h4" gutterBottom>
          7. Modifications to Terms
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to modify these Terms of Service at any time. We will notify users of any significant changes.
        </Typography>

        <Typography variant="h4" gutterBottom>
          8. Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these Terms, please contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          Email: support@roborecon2.com
          <br />
          Website: https://www.roborecon2.com/contact
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;