import React, { useState, useEffect, useCallback } from 'react';
import { 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
  Select,
  MenuItem
} from '@mui/material';
import createApi from '../services/api';

const api = createApi();

const TBAMapping = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [tbaDataKeys, setTbaDataKeys] = useState([]);
  const [mappings, setMappings] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const [dataKeys, existingMappings] = await Promise.all([
        api.fetchTBADataKeys(year),
        api.fetchTBAMappings(year)
      ]);
      setTbaDataKeys(dataKeys);
      setMappings(existingMappings);
    } catch (err) {
      setError('Failed to fetch TBA data');
    } finally {
      setLoading(false);
    }
  }, [year]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleMappingChange = (tbaKey, customName) => {
    setMappings({
      ...mappings,
      [tbaKey]: customName
    });
  };

  const handleSaveMappings = async () => {
    try {
      await api.saveTBAMapping(year, mappings);
      setSuccess('Mappings saved successfully');
    } catch (err) {
      setError('Failed to save mappings');
    }
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        TBA Data Mapping
      </Typography>
      <Select
        value={year}
        onChange={handleYearChange}
        style={{ marginBottom: '20px' }}
      >
        {[...Array(5)].map((_, i) => (
          <MenuItem key={i} value={new Date().getFullYear() - i}>
            {new Date().getFullYear() - i}
          </MenuItem>
        ))}
      </Select>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>TBA Data Key</TableCell>
              <TableCell>Custom Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tbaDataKeys.map((tbaKey) => (
              <TableRow key={tbaKey}>
                <TableCell>{tbaKey}</TableCell>
                <TableCell>
                  <TextField
                    value={mappings[tbaKey] || ''}
                    onChange={(e) => handleMappingChange(tbaKey, e.target.value)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button 
        variant="contained" 
        onClick={handleSaveMappings} 
        style={{ marginTop: '20px' }}
      >
        Save Mappings
      </Button>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess('')}>
        <Alert onClose={() => setSuccess('')} severity="success">
          {success}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TBAMapping;