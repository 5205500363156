import React, { useState, useEffect } from 'react';
import { Box, Typography, Select, MenuItem, CircularProgress } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function PracticeMatchGraph({ api }) {
  const [metrics, setMetrics] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState('');
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await api.getPracticeMatchMetrics();
        setMetrics(response);
        if (response.length > 0) {
          setSelectedMetric(response[0]);
        }
      } catch (error) {
        console.error('Error fetching practice match metrics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, [api]);

  useEffect(() => {
    const fetchGraphData = async () => {
      if (selectedMetric) {
        setLoading(true);
        try {
          const response = await api.getPracticeMatchGraphData(selectedMetric);
          const processedData = response.map(item => {
            if (typeof item.value === 'object' && item.value !== null && 'made' in item.value && 'missed' in item.value) {
              return {
                ...item,
                made: item.value.made,
                missed: item.value.missed
              };
            }
            return item;
          });
          setGraphData(processedData);
        } catch (error) {
          console.error('Error fetching graph data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchGraphData();
  }, [api, selectedMetric]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
          <p className="label">{`Timestamp: ${label}`}</p>
          {payload.map((pld, index) => (
            <p key={index} style={{ color: pld.color }}>
              {`${pld.name}: ${pld.value}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Practice Match Graph</Typography>
      <Select
        value={selectedMetric}
        onChange={(e) => setSelectedMetric(e.target.value)}
        fullWidth
        margin="normal"
      >
        {metrics.map((metric, index) => (
          <MenuItem key={index} value={metric}>{metric}</MenuItem>
        ))}
      </Select>
      {graphData.length > 0 ? (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={graphData}>
            <XAxis dataKey="timestamp" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            {graphData[0].made !== undefined ? (
              <>
                <Line type="monotone" dataKey="made" stroke="#8884d8" name="Made" />
                <Line type="monotone" dataKey="missed" stroke="#82ca9d" name="Missed" />
              </>
            ) : (
              <Line type="monotone" dataKey="value" stroke="#8884d8" name={selectedMetric} />
            )}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <Typography>No data available for the selected metric</Typography>
      )}
    </Box>
  );
}

export default PracticeMatchGraph;