const getApiBaseUrl = () => {
  const apiUrl = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL || 'https://api.roborecon.net'
    : process.env.REACT_APP_API_URL || 'http://localhost:5000';

  console.log(`[${new Date().toISOString()}] api.Config.js - NODE_ENV:`, process.env.NODE_ENV);
  console.log(`[${new Date().toISOString()}] api.Config.js - REACT_APP_API_URL:`, process.env.REACT_APP_API_URL);
  console.log(`[${new Date().toISOString()}] api.Config.js - Determined API Base URL:`, apiUrl);

  return apiUrl;
};

export default getApiBaseUrl;