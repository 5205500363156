import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import createApi from '../services/api';
import EventAnalyticsReportsPreview from './EventAnalyticsReportsPreview';

const api = createApi();

const FullWidthContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100% !important',
    padding: theme.spacing(0, 4),
  },
}));

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  '& .MuiDataGrid-root': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
  '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader, & .MuiDataGrid-columnHeaderTitle': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-cell': {
    fontSize: 14,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiDataGrid-toolbarContainer': {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
}));

const formatTeamName = (name) => {
  let formattedName = name.trim();
  if (formattedName.toLowerCase().startsWith('the ')) {
    formattedName = formattedName.slice(4);
  }
  if (formattedName.toLowerCase().endsWith(' robotics')) {
    formattedName = formattedName.slice(0, -9);
  }
  return formattedName.trim();
};

const truncateEventTitle = (title) => {
  return title.replace(/\s+(presented|sponsored|hosted)\s+by\s+.*/i, '').trim();
};

const safeJSONParse = (value) => {
  if (typeof value === 'string') {
    try {
      return JSON.parse(value);
    } catch (e) {
      console.log('Failed to parse JSON string:', value);
      return value;
    }
  }
  return value;
};

const EventAnalytics = () => {
  const { eventKey } = useParams();
  const year = eventKey.substring(0, 4);
  const [data, setData] = useState([]);
  const [availableMetrics, setAvailableMetrics] = useState([]);
  const [error, setError] = useState('');
  const [noAnalyticsData, setNoAnalyticsData] = useState(false);
  const [noMetricsData, setNoMetricsData] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [eventName, setEventName] = useState('');
  const [columnOrder, setColumnOrder] = useState(() => {
    const savedOrder = localStorage.getItem(`columnOrder_${eventKey}`);
    return savedOrder ? JSON.parse(savedOrder) : null;
  });

  const processScoutingData = async (rawData) => {
    console.log('Processing scouting data:', rawData);
    const teamData = {};
    rawData.forEach((entry, index) => {
      console.log(`Processing entry ${index}:`, entry);
      const teamKey = entry.team_key;
      if (!teamData[teamKey]) {
        teamData[teamKey] = {
          id: teamKey,
          teamNumber: parseInt(teamKey.replace('frc', ''), 10),
          teamNickname: '',
          matches: []
        };
      }
      const parsedData = safeJSONParse(entry.data);
      teamData[teamKey].matches.push(parsedData);
    });

    const teamNumbers = Object.keys(teamData).map(key => key.replace('frc', ''));
    const teamDetails = await Promise.all(teamNumbers.map(number => api.getTeamData(number)));

    teamDetails.forEach(team => {
      if (team && teamData[`frc${team.team_number}`]) {
        teamData[`frc${team.team_number}`].teamNickname = formatTeamName(team.nickname || `Team ${team.team_number}`);
      }
    });

    return Object.values(teamData).map(team => {
      const aggregatedData = team.matches.reduce((acc, match) => {
        Object.keys(match).forEach(key => {
          const value = match[key];
          if (typeof value === 'number') {
            acc[key] = (acc[key] || 0) + value;
          } else if (typeof value === 'object' && value !== null) {
            acc[key] = acc[key] || { made: 0, missed: 0 };
            if ('made' in value) acc[key].made = (acc[key].made || 0) + (value.made || 0);
            if ('missed' in value) acc[key].missed = (acc[key].missed || 0) + (value.missed || 0);
          } else if (typeof value === 'string') {
            if (value.trim() !== '') {
              acc[key] = value;
            }
          }
        });
        return acc;
      }, {});

      Object.keys(aggregatedData).forEach(key => {
        if (typeof aggregatedData[key] === 'number') {
          aggregatedData[key] /= team.matches.length;
        } else if (typeof aggregatedData[key] === 'object' && aggregatedData[key] !== null) {
          if ('made' in aggregatedData[key]) aggregatedData[key].made /= team.matches.length;
          if ('missed' in aggregatedData[key]) aggregatedData[key].missed /= team.matches.length;
        }
      });

      return { ...team, ...aggregatedData };
    });
  };

  const fetchEventName = useCallback(async () => {
    try {
      const eventDetails = await api.fetchEventDetails(eventKey);
      let name = eventDetails.name || eventDetails.short_name || eventKey;
      name = truncateEventTitle(name);
      setEventName(name);
    } catch (err) {
      console.error('Failed to fetch event name:', err);
      setEventName(eventKey);
    }
  }, [eventKey]);

  const fetchData = useCallback(async () => {
    try {
      const [analyticsData, metrics] = await Promise.all([
        api.fetchAnalyticsData(year, eventKey),
        api.fetchAvailableMetrics()
      ]);
      const processedData = await processScoutingData(analyticsData);
      setData(processedData);
      setAvailableMetrics(metrics);
      setNoAnalyticsData(processedData.length === 0);
      setNoMetricsData(metrics.length === 0);
    } catch (err) {
      console.error('Error fetching data:', err);
      if (err.response && err.response.status === 404) {
        if (err.response.data.message.includes('No data found')) {
          setNoAnalyticsData(true);
          setData([]);
        } else if (err.response.data.message.includes('No metrics found')) {
          setNoMetricsData(true);
          setAvailableMetrics([]);
        }
      } else {
        setError('Failed to fetch data: ' + err.message);
      }
    }
  }, [year, eventKey]);

  useEffect(() => {
    fetchData();
    fetchEventName();
  }, [fetchData, fetchEventName]);

  const handleTeamClick = (teamNumber) => {
    setSelectedTeam(teamNumber);
    setIsDialogOpen(true);
  };

  const columns = useMemo(() => {
    if (data.length === 0) return [];

    const sampleRow = data[0];
    const cols = Object.keys(sampleRow)
      .filter(key => key !== 'id' && key !== 'matches') // Hide 'id' and 'matches' columns
      .map(key => {
        let column = {
          field: key,
          headerName: key === 'teamNumber' ? 'Team #' : key === 'teamNickname' ? 'Team Name' : key,
          width: 150,
          headerClassName: 'super-app-theme--header',
        };

        if (key === 'teamNumber') {
          column.width = 100;
          column.pinned = 'left';
          column.renderCell = (params) => (
            <Button color="primary" onClick={() => handleTeamClick(params.value)}>
              {params.value}
            </Button>
          );
        } else if (key === 'teamNickname') {
          column.width = 200;
        } else if (typeof sampleRow[key] === 'object' && sampleRow[key] !== null) {
          column.renderCell = (params) => {
            const made = typeof params.value?.made === 'number' ? params.value.made.toFixed(2) : 'N/A';
            const missed = typeof params.value?.missed === 'number' ? params.value.missed.toFixed(2) : 'N/A';
            return `${made} / ${missed}`;
          };
        } else if (typeof sampleRow[key] === 'number') {
          column.renderCell = (params) => (typeof params.value === 'number' ? params.value.toFixed(2) : params.value);
        }

        return column;
      });

    // Apply saved column order if available
    if (columnOrder) {
      cols.sort((a, b) => {
        const indexA = columnOrder.indexOf(a.field);
        const indexB = columnOrder.indexOf(b.field);
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
      });
    }

    return cols;
  }, [data, columnOrder]);

  const handleColumnOrderChange = (newOrder) => {
    setColumnOrder(newOrder);
    localStorage.setItem(`columnOrder_${eventKey}`, JSON.stringify(newOrder));
  };

  return (
    <FullWidthContainer>
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          {eventName}
        </Typography>
      </Box>

      {noAnalyticsData && (
        <Box my={4}>
          <Typography variant="h6" color="error">
            No analytics data available for the selected event and year.
          </Typography>
        </Box>
      )}

      {noMetricsData && (
        <Box my={4}>
          <Typography variant="h6" color="error">
            No metrics available in the analytics data.
          </Typography>
        </Box>
      )}

      {data.length > 0 && availableMetrics.length > 0 && (
        <Box my={4} style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
          <StyledDataGrid
            rows={data}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[25, 50, 100]}
            checkboxSelection
            disableSelectionOnClick
            components={{
              Toolbar: GridToolbar,
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            onColumnOrderChange={handleColumnOrderChange}
            columnBuffer={columns.length}
            disableColumnReorder={false}
          />
        </Box>
      )}
      
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Individual Scouting Data for Team {selectedTeam}
          <IconButton
            aria-label="close"
            onClick={() => setIsDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <EventAnalyticsReportsPreview teamNumber={selectedTeam} eventKey={eventKey} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </FullWidthContainer>
  );
};

export default EventAnalytics;