import React from 'react';
import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableRow, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function PracticeMatchPreview({ match, onClose }) {
  if (!match) {
    return null;
  }

  const renderValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      if ('made' in value && 'missed' in value) {
        return `Made: ${value.made}, Missed: ${value.missed}`;
      }
      return JSON.stringify(value);
    }
    return String(value);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const renderMatchData = (data) => {
    const rows = [];

    const addRow = (key, value, depth = 0) => {
      const displayKey = key.split('.').pop(); // Get the last part of the key
      if (typeof value === 'object' && value !== null) {
        if (key !== 'data') {
          rows.push(
            <TableRow key={key}>
              <TableCell component="th" scope="row" style={{ paddingLeft: `${depth * 16}px` }}>
                <strong>{displayKey}</strong>
              </TableCell>
              <TableCell>{renderValue(value)}</TableCell>
            </TableRow>
          );
        }
        if (!('made' in value && 'missed' in value)) {
          Object.entries(value).forEach(([subKey, subValue]) => {
            addRow(`${key}.${subKey}`, subValue, key === 'data' ? depth : depth + 1);
          });
        }
      } else {
        rows.push(
          <TableRow key={key}>
            <TableCell component="th" scope="row" style={{ paddingLeft: `${depth * 16}px` }}>{displayKey}</TableCell>
            <TableCell>{renderValue(value)}</TableCell>
          </TableRow>
        );
      }
    };

    Object.entries(data).forEach(([key, value]) => {
      if (!['timestamp', 'id', 'created_at', 'updated_at'].includes(key)) {
        addRow(key, value);
      }
    });

    return rows;
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
        maxHeight: '80vh',
        overflowY: 'auto',
        width: '80%',
        maxWidth: '600px',
      }}
    >
      <Paper elevation={6} sx={{ backgroundColor: '#284d67' }}>
        <Box p={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">Practice Match Details</Typography>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">Date</TableCell>
                  <TableCell>{formatDate(match.timestamp)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Time</TableCell>
                  <TableCell>{new Date(match.timestamp).toLocaleTimeString()}</TableCell>
                </TableRow>
                {renderMatchData(match)}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
}

export default PracticeMatchPreview;