import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, Button, CircularProgress } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { createApi } from '../services/api';

/**
 * PermissionsPage component for managing user permissions
 * This page is only accessible to the admin user (nickfoote@team2102.org)
 */
const PermissionsPage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const { user } = useAuth();
  const api = createApi();

  // Available pages and tabs
  const availablePages = ['EventList', 'EventDetails', 'ScoutingForm', 'Analytics', 'PracticeMatch'];

  /**
   * Fetch users and their permissions from the server
   */
  const fetchUsers = useCallback(async () => {
    try {
      const response = await api.get('/api/permissions/users');
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
    }
  }, [api]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  /**
   * Handle permission change for a user
   * @param {number} userId - The ID of the user
   * @param {string} page - The page or tab name
   * @param {boolean} isChecked - Whether the permission is being granted or revoked
   */
  const handlePermissionChange = (userId, page, isChecked) => {
    setUsers(users.map(user => {
      if (user.id === userId) {
        const updatedPermissions = isChecked
          ? [...user.permissions, page]
          : user.permissions.filter(p => p !== page);
        return { ...user, permissions: updatedPermissions };
      }
      return user;
    }));
  };

  /**
   * Save the updated permissions to the server
   */
  const savePermissions = async () => {
    setSaving(true);
    try {
      for (const user of users) {
        await api.put(`/api/permissions/users/${user.id}/permissions`, {
          permissions: user.permissions
        });
      }
      alert('Permissions saved successfully');
    } catch (error) {
      console.error('Error saving permissions:', error);
      alert('Error saving permissions');
    }
    setSaving(false);
  };

  if (user.email !== 'nickfoote@team2102.org') {
    return <Typography variant="h6">You do not have permission to view this page.</Typography>;
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>User Permissions</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              {availablePages.map(page => (
                <TableCell key={page}>{page}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <TableRow key={user.id}>
                <TableCell>{user.email}</TableCell>
                {availablePages.map(page => (
                  <TableCell key={`${user.id}-${page}`}>
                    <Checkbox
                      checked={user.permissions.includes(page)}
                      onChange={(e) => handlePermissionChange(user.id, page, e.target.checked)}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={savePermissions} 
        style={{ marginTop: '20px' }}
        disabled={saving}
      >
        {saving ? 'Saving...' : 'Save Permissions'}
      </Button>
    </div>
  );
};

export default PermissionsPage;