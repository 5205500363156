import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function PracticeMatchList({ api, onSelectMatch }) {
  const [practiceMatches, setPracticeMatches] = useState([]);

  const fetchPracticeMatches = useCallback(async () => {
    try {
      const response = await api.getPracticeMatches();
      setPracticeMatches(response);
    } catch (error) {
      console.error('Error fetching practice matches:', error);
    }
  }, [api]);

  useEffect(() => {
    fetchPracticeMatches();
  }, [fetchPracticeMatches]);

  const handleRowClick = (match) => {
    onSelectMatch(match);
  };

  const handleDelete = async (event, matchId) => {
    event.stopPropagation();
    try {
      await api.deletePracticeMatch(matchId);
      fetchPracticeMatches(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting practice match:', error);
    }
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Practice Match List</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              {/* Add cells for each metric */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {practiceMatches.map((match, index) => (
              <TableRow 
                key={index} 
                onClick={() => handleRowClick(match)}
                style={{ cursor: 'pointer' }}
                hover
              >
                <TableCell>{new Date(match.timestamp).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(match.timestamp).toLocaleTimeString()}</TableCell>
                {/* Add cells for each metric */}
                <TableCell>
                  <IconButton
                    onClick={(event) => handleDelete(event, match.id)}
                    size="small"
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PracticeMatchList;