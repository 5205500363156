'use client';

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { TextField, Select, MenuItem, FormControl, InputLabel, Button, Typography, Box, CircularProgress } from '@mui/material';
import { updateUserSettings, fetchUserSettings } from '../app/actions/userActions';

/**
 * UserSettings component for managing user profile information
 * @returns {JSX.Element} The UserSettings component
 */
export default function UserSettings() {
  const { user, updateUser } = useAuth();
  const [nickname, setNickname] = useState('');
  const [teamNumber, setTeamNumber] = useState('');
  const [role, setRole] = useState('');
  const [graduatingYear, setGraduatingYear] = useState('');
  const [studentName, setStudentName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUserSettings = async () => {
      setLoading(true);
      try {
        const result = await fetchUserSettings();
        if (result.success) {
          const settings = result.settings;
          setNickname(settings.nickname || '');
          setTeamNumber(settings.team_number ? settings.team_number.toString() : '');
          setRole(settings.role || '');
          setGraduatingYear(settings.graduating_year ? settings.graduating_year.toString() : '');
          setStudentName(settings.student_name || '');
        } else {
          console.error('Failed to fetch user settings:', result.error);
        }
      } catch (error) {
        console.error('Error fetching user settings:', error);
      }
      setLoading(false);
    };

    loadUserSettings();
  }, []);

  const currentYear = new Date().getFullYear();
  const graduatingYearOptions = Array.from({ length: 6 }, (_, i) => currentYear + i);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedSettings = {
      nickname,
      team_number: teamNumber ? parseInt(teamNumber, 10) : null,
      role,
      graduating_year: (role === 'Student' || role === 'Parent') && graduatingYear ? parseInt(graduatingYear, 10) : null,
      student_name: role === 'Parent' ? studentName : null,
    };

    try {
      const result = await updateUserSettings(updatedSettings);
      if (result.success) {
        updateUser(result.user);
        alert('Settings updated successfully!');
      } else {
        alert('Failed to update settings. Please try again.');
      }
    } catch (error) {
      console.error('Error updating settings:', error);
      alert('An error occurred while updating settings.');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    return <Typography>Please log in to access user settings.</Typography>;
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400, margin: 'auto', mt: 4 }}>
      <Typography variant="h4" gutterBottom>User Settings</Typography>
      
      <TextField
        fullWidth
        margin="normal"
        label="First Name"
        value={user.firstName || ''}
        disabled
      />
      
      <TextField
        fullWidth
        margin="normal"
        label="Last Name"
        value={user.lastName || ''}
        disabled
      />
      
      <TextField
        fullWidth
        margin="normal"
        label="Nickname"
        value={nickname}
        onChange={(e) => setNickname(e.target.value)}
      />
      
      <TextField
        fullWidth
        margin="normal"
        label="Team Number"
        type="number"
        value={teamNumber}
        onChange={(e) => setTeamNumber(e.target.value)}
      />
      
      <FormControl fullWidth margin="normal">
        <InputLabel>Role</InputLabel>
        <Select
          value={role}
          onChange={(e) => setRole(e.target.value)}
          label="Role"
        >
          <MenuItem value="Student">Student</MenuItem>
          <MenuItem value="Mentor">Mentor</MenuItem>
          <MenuItem value="Alumni">Alumni</MenuItem>
          <MenuItem value="Parent">Parent</MenuItem>
          <MenuItem value="Sponsor">Sponsor</MenuItem>
        </Select>
      </FormControl>
      
      {(role === 'Student' || role === 'Parent') && (
        <FormControl fullWidth margin="normal">
          <InputLabel>Graduating Year</InputLabel>
          <Select
            value={graduatingYear}
            onChange={(e) => setGraduatingYear(e.target.value)}
            label="Graduating Year"
          >
            {graduatingYearOptions.map((year) => (
              <MenuItem key={year} value={year.toString()}>{year}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      
      {role === 'Parent' && (
        <TextField
          fullWidth
          margin="normal"
          label="Student Name"
          value={studentName}
          onChange={(e) => setStudentName(e.target.value)}
        />
      )}
      
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        Save Settings
      </Button>
    </Box>
  );
}