import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import {
  CssBaseline, ThemeProvider, createTheme, Box, CircularProgress, useMediaQuery, Container
} from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';

import TopMenu from './components/TopMenu';
import EventList from './components/EventList';
import EventDetails from './components/EventDetails';
import ScoutingConfigPage from './components/ScoutingConfigPage';
import ScoutingPage from './components/ScoutingPage';
import PracticeMatchPage from './components/PracticeMatchPage';
import PracticeMatchConfig from './components/PracticeMatchConfig';
import PracticeMatchList from './components/PracticeMatchList';
import PracticeMatchGraph from './components/PracticeMatchGraph';
import Analytics from './components/Analytics';
import EventAnalytics from './components/EventAnalytics';
import TBAMapping from './components/TBAMapping';
import PermissionsPage from './components/PermissionsPage';
import UserSettings from './components/UserSettings';
import Login from './components/Login';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import Footer from './components/Footer';
import { createApi } from './services/api';
import { AuthProvider, AuthContext } from './contexts/AuthContext';

// Create a theme instance with dark mode
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9'
    },
    secondary: {
      main: '#f48fb1'
    },
    background: {
      default: '#303030',
      paper: '#424242'
    }
  }
});

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

// Custom hook to determine maxWidth based on screen size
const useCustomMaxWidth = () => {
  const isXl = useMediaQuery('(min-width:1920px)');
  const isLg = useMediaQuery('(min-width:1280px)');
  const isMd = useMediaQuery('(min-width:960px)');

  if (isXl) return '95%';
  if (isLg) return '95%';
  if (isMd) return '95%';
  return 'lg'; // Default to 'lg' for smaller screens
};

/**
 * Main App component
 * @param {Object} props - Component props
 * @param {string} props.apiUrl - API URL for the application
 * @returns {React.Component} App component
 */
function App({ apiUrl }) {
  console.log('App rendered with apiUrl:', apiUrl);
  const customMaxWidth = useCustomMaxWidth();

  // Initialize API
  React.useEffect(() => {
    createApi(apiUrl);
  }, [apiUrl]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Box display="flex" flexDirection="column" minHeight="100vh">
              <TopMenu />
              <Container maxWidth={customMaxWidth} style={{ flex: 1 }}>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/" element={<ProtectedRoute><EventList /></ProtectedRoute>} />
                  <Route path="/events/:eventKey" element={<ProtectedRoute><EventDetails /></ProtectedRoute>} />
                  <Route path="/scouting-config" element={<ProtectedRoute><ScoutingConfigPage /></ProtectedRoute>} />
                  <Route path="/scouting" element={<ProtectedRoute><ScoutingPage /></ProtectedRoute>} />
                  <Route path="/events/:eventKey/:teamKey/:matchKey" element={<ProtectedRoute><ScoutingPage /></ProtectedRoute>} />
                  <Route path="/practice-match" element={<ProtectedRoute><PracticeMatchPage /></ProtectedRoute>} />
                  <Route path="/practice-match-config" element={<ProtectedRoute><PracticeMatchConfig /></ProtectedRoute>} />
                  <Route path="/practice-match-list" element={<ProtectedRoute><PracticeMatchList /></ProtectedRoute>} />
                  <Route path="/practice-match-graph" element={<ProtectedRoute><PracticeMatchGraph /></ProtectedRoute>} />
                  <Route path="/analytics" element={<ProtectedRoute><Analytics /></ProtectedRoute>} />
                  <Route path="/event-analytics/:eventKey" element={<ProtectedRoute><EventAnalytics /></ProtectedRoute>} />
                  <Route path="/tba-mapping" element={<ProtectedRoute><TBAMapping /></ProtectedRoute>} />
                  <Route path="/permissions" element={<ProtectedRoute><PermissionsPage /></ProtectedRoute>} />
                  <Route path="/user-settings" element={<ProtectedRoute><UserSettings /></ProtectedRoute>} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/terms-of-service" element={<TermsOfService />} />
                </Routes>
              </Container>
              <Footer />
            </Box>
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;