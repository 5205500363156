export const getTeamNickname = (teamNumber, teams) => {
  const team = teams.find(t => t.team_number === parseInt(teamNumber));
  return team ? team.nickname : 'Unknown Team';
};

export const getAdditionalRPInfo = (match, alliance) => {
  if (!match.score_breakdown || !match.score_breakdown[alliance]) {
    console.log(`No score breakdown for ${alliance} alliance in match:`, match.key);
    return { count: 0, reason: 'RP information not available' };
  }

  const breakdown = match.score_breakdown[alliance];
  let additionalRP = 0;
  let reasons = [];

  const year = parseInt(match.event_key.substring(0, 4));

  const knownBonuses = {
    2024: ['melodyBonusAchieved', 'ensembleBonusAchieved'],
    2023: ['sustainabilityBonusAchieved', 'activationBonusAchieved'],
  };

  const bonusesToCheck = knownBonuses[year] || Object.keys(breakdown).filter(key => 
    key.endsWith('BonusAchieved') || key.includes('RankingPoint')
  );

  bonusesToCheck.forEach(bonus => {
    if (breakdown[bonus] === true) {
      additionalRP++;
      reasons.push(bonus.replace('BonusAchieved', '').replace('RankingPoint', '').replace(/([A-Z])/g, ' $1').trim());
    }
  });

  console.log(`Match ${match.key}, ${alliance} alliance:`, {
    year: year,
    breakdown: breakdown,
    additionalRP: additionalRP,
    reasons: reasons
  });

  return {
    count: additionalRP,
    reason: reasons.length > 0 ? reasons.join(' and ') : 'No additional RP'
  };
};

export const getTBAMatchUrl = (match) => {
  const year = match.event_key.substring(0, 4);
  const eventCode = match.event_key.substring(4);
  const matchType = match.comp_level.toLowerCase();
  const matchNumber = match.match_number;
  return `https://www.thebluealliance.com/match/${year}${eventCode}_${matchType}${matchNumber}`;
};