import axios from 'axios';
import getApiBaseUrl from '../config/api.Config';

let apiInstance = null;

const createApi = (apiUrl) => {
  if (apiInstance) {
    return apiInstance;
  }

  const API_BASE_URL = getApiBaseUrl(apiUrl);
  console.log(`[${new Date().toISOString()}] api.js - Final API_BASE_URL:`, API_BASE_URL);
  console.log(`[${new Date().toISOString()}] api.js - Current NODE_ENV:`, process.env.NODE_ENV);
  console.log(`[${new Date().toISOString()}] api.js - REACT_APP_API_URL:`, process.env.REACT_APP_API_URL);

  const api = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    timeout: 15000, // Increased timeout to 15 seconds
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Add a request interceptor
  api.interceptors.request.use((config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    console.log(`[${new Date().toISOString()}] api.js - Request config:`, JSON.stringify(config, null, 2));
    return config;
  }, (error) => {
    console.error(`[${new Date().toISOString()}] api.js - Request interceptor error:`, error);
    return Promise.reject(error);
  });

  // Add a response interceptor
  api.interceptors.response.use((response) => {
    console.log(`[${new Date().toISOString()}] api.js - Response:`, JSON.stringify(response.data, null, 2));
    return response;
  }, async (error) => {
    console.error(`[${new Date().toISOString()}] api.js - Response interceptor error:`, error);
    if (error.response) {
      console.error('Error response:', error.response);
      console.error('Error response status:', error.response.status);
      console.error('Error response data:', error.response.data);
      console.error('Error response headers:', error.response.headers);
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          throw new Error('No refresh token available');
        }
        const response = await api.post('/api/auth/refresh', { refreshToken });
        const { token } = response.data;
        localStorage.setItem('authToken', token);
        originalRequest.headers['Authorization'] = `Bearer ${token}`;
        return api(originalRequest);
      } catch (refreshError) {
        console.error('Failed to refresh token:', refreshError);
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  });

  const request = async (method, url, data = null, config = {}) => {
    try {
      const fullUrl = `${API_BASE_URL}${url}`;
      console.log(`[${new Date().toISOString()}] api.js - Making ${method.toUpperCase()} request to: ${fullUrl}`);
      console.log(`[${new Date().toISOString()}] api.js - Request config:`, JSON.stringify(config, null, 2));
      if (data) {
        console.log(`[${new Date().toISOString()}] api.js - Request data:`, JSON.stringify(data, null, 2));
      }
      
      // Log the full URL with query parameters for GET requests
      if (method.toLowerCase() === 'get' && config.params) {
        const queryString = new URLSearchParams(config.params).toString();
        console.log(`[${new Date().toISOString()}] api.js - Full GET URL with params: ${fullUrl}?${queryString}`);
      }

      const response = await api[method](url, data, { ...config, withCredentials: true });
      console.log(`[${new Date().toISOString()}] api.js - Successful ${method.toUpperCase()} response from: ${fullUrl}`);
      console.log(`[${new Date().toISOString()}] api.js - Response data:`, JSON.stringify(response.data, null, 2));
      return response.data;
    } catch (error) {
      console.error(`[${new Date().toISOString()}] api.js - Error in ${method.toUpperCase()} request to ${API_BASE_URL}${url}:`, error);
      console.error(`[${new Date().toISOString()}] Full error object:`, error);
      if (error.response) {
        console.error(`[${new Date().toISOString()}] Error response status:`, error.response.status);
        console.error(`[${new Date().toISOString()}] Error response data:`, JSON.stringify(error.response.data, null, 2));
        console.error(`[${new Date().toISOString()}] Error response headers:`, JSON.stringify(error.response.headers, null, 2));
      } else if (error.request) {
        console.error(`[${new Date().toISOString()}] Error request:`, error.request);
      } else {
        console.error(`[${new Date().toISOString()}] Error message:`, error.message);
      }
      throw error;
    }
  };

  apiInstance = {
    // Authentication methods
    authenticateWithGoogle: async (credential) => {
      console.log(`[${new Date().toISOString()}] api.js - Authenticating with Google, credential:`, credential);
      try {
        const response = await api.post('/api/auth/google', { credential }, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        });
        console.log(`[${new Date().toISOString()}] api.js - Google authentication successful:`, response);
        return response.data;
      } catch (error) {
        console.error(`[${new Date().toISOString()}] api.js - Google authentication error:`, error);
        if (error.response) {
          console.error(`[${new Date().toISOString()}] api.js - Error response:`, error.response);
          console.error(`[${new Date().toISOString()}] api.js - Error response headers:`, error.response.headers);
        } else if (error.request) {
          console.error(`[${new Date().toISOString()}] api.js - No response received:`, error.request);
        } else {
          console.error(`[${new Date().toISOString()}] api.js - Error setting up request:`, error.message);
        }
        if (error.code === 'ECONNABORTED') {
          console.error(`[${new Date().toISOString()}] api.js - Request timed out`);
        }
        throw error;
      }
    },
    verifyToken: () => request('get', '/api/auth/verify'),
    refreshToken: (refreshToken) => request('post', '/api/auth/refresh', { refreshToken }),

    // Existing API methods
    fetchEvents: (url) => request('get', url),
    fetchEventsByYear: (year) => request('get', `/api/events/${year}/teams`),
    fetchEventsByTeamAndYear: (teamNumber, year) => request('get', `/api/events/${year}/${teamNumber}`),
    fetchEventMatches: (eventKey) => request('get', `/api/events/${eventKey}/matches`),
    fetchEventDetails: (eventKey) => request('get', `/api/events/${eventKey}`),
    fetchEventTeams: (eventKey) => request('get', `/api/events/${eventKey}/teams`),
    fetchTeamEventStatus: (eventKey, teamKey) => request('get', `/api/team/${teamKey}/event/${eventKey}/status`),
    fetchEventRankings: (eventKey) => request('get', `/api/rankings/${eventKey}/rankings`),
    fetchEventAlliances: (eventKey) => request('get', `/api/tba/event/${eventKey}/alliances`),
    submitScoutingData: (data) => {
      console.log('[api.js] Submitting scouting data:', data);
      const payload = {
        eventKey: data.eventKey,
        matchKey: data.matchKey,
        teamKey: data.teamKey,
        data: data.scoutingData
      };
      console.log('[api.js] Structured payload:', payload);
      return request('post', '/api/scouting-data', payload);
    },
    getNextMatch: (params) => {
      console.log('[api.js] getNextMatch called with params:', JSON.stringify(params, null, 2));
      const url = '/api/tba/next-match';
      const fullUrl = `${API_BASE_URL}${url}?${new URLSearchParams(params).toString()}`;
      console.log('[api.js] Full URL for getNextMatch:', fullUrl);
      return request('get', url, null, { params })
        .then(response => {
          console.log('[api.js] Next match response:', JSON.stringify(response, null, 2));
          return response;
        })
        .catch(error => {
          console.error('[api.js] Error getting next match:', error);
          console.error('[api.js] Error details:', JSON.stringify(error, null, 2));
          throw error;
        });
    },
    getScoutingConfig: (year) => request('get', `/api/scouting-config/${year}`),
    saveScoutingConfig: (year, data) => request('post', `/api/scouting-config/${year}`, data),
    getTeamData: (teamNumber) => request('get', `/api/teams/${teamNumber}`),
    getMatchData: (matchKey) => request('get', `/api/matches/${matchKey}`),
    getPracticeMatches: () => request('get', '/api/practice-match'),
    submitPracticeMatchData: (data) => request('post', '/api/practice-match', data),
    getPracticeMatchMetrics: () => request('get', '/api/practice-match/metrics'),
    getPracticeMatchGraphData: (metric) => request('get', `/api/practice-match/graph/${metric}`),
    savePracticeMatchConfig: (config) => request('post', '/api/practice-match/config', config),
    getPracticeMatchConfig: () => request('get', '/api/practice-match/config'),
    deletePracticeMatch: (matchId) => request('delete', `/api/practice-match/${matchId}`),
    getTeamEPA: (teamNumber, year) => request('get', `/api/statbotics/team/${teamNumber}/epa/${year}`),
    fetchTeamsEPA: (teams, year) => request('post', '/api/statbotics/teams/epa', { teams, year }),
    fetchAnalyticsData: (year, event) => request('get', `/api/analytics/${year}/${event}`),
    fetchAvailableMetrics: () => request('get', '/api/analytics/metrics'),
    fetchScoutingDataForTeamAtEvent: (teamNumber, eventKey) => request('get', `/api/scouting-data/${eventKey}/${teamNumber}`),
    searchTeams: async () => {
      try {
        console.log('[api.js] Fetching all teams');
        let allTeams = [];
        let page = 0;
        let hasMore = true;

        while (hasMore) {
          const response = await request('get', `/teams/${page}`);
          if (response && response.length > 0) {
            allTeams = allTeams.concat(response);
            page++;
          } else {
            hasMore = false;
          }
        }

        console.log(`[api.js] Fetched ${allTeams.length} teams`);
        return allTeams;
      } catch (error) {
        console.error('[api.js] Error in searchTeams:', error);
        return [];
      }
    },
  };

  // Check if the API is accessible
  api.get('/')
    .then(() => console.log(`[${new Date().toISOString()}] api.js - API is accessible`))
    .catch((error) => console.error(`[${new Date().toISOString()}] api.js - Error accessing API:`, error));

  return apiInstance;
};

export { createApi as default, createApi };
export const getApi = () => {
  if (!apiInstance) {
    throw new Error('API not initialized. Call createApi first.');
  }
  return apiInstance;
};

// Log the current environment
console.log(`[${new Date().toISOString()}] api.js - Current NODE_ENV:`, process.env.NODE_ENV);
console.log(`[${new Date().toISOString()}] api.js - REACT_APP_API_URL:`, process.env.REACT_APP_API_URL);