import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { getApi } from '../services/api';

export const AuthContext = createContext();

/**
 * Custom hook to use the AuthContext
 * @returns {Object} The auth context value
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

/**
 * AuthProvider component to manage global authentication state
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components
 * @returns {JSX.Element} AuthProvider component
 */
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const checkAuthStatus = useCallback(async () => {
    console.log('Checking auth status...');
    const token = localStorage.getItem('authToken');
    let authState = false;
    let userData = null;

    if (token) {
      console.log('Token found in localStorage');
      try {
        const api = getApi();
        const response = await api.verifyToken();
        if (response.success) {
          console.log('Token verified successfully');
          authState = true;
          userData = response.user;
        } else {
          console.log('Token invalid, attempting to refresh');
          // If token is invalid, try to refresh it
          const refreshToken = localStorage.getItem('refreshToken');
          if (refreshToken) {
            const refreshResponse = await api.refreshToken(refreshToken);
            if (refreshResponse.success) {
              console.log('Token refreshed successfully');
              localStorage.setItem('authToken', refreshResponse.token);
              authState = true;
              userData = refreshResponse.user;
            } else {
              throw new Error('Failed to refresh token');
            }
          } else {
            throw new Error('No refresh token available');
          }
        }
      } catch (error) {
        console.error('Error verifying auth status:', error);
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
      }
    } else {
      console.log('No token found in localStorage');
    }

    setIsAuthenticated(authState);
    setUser(userData);
    setLoading(false);
    console.log('Auth status check complete. isAuthenticated:', authState);
  }, []);

  useEffect(() => {
    console.log('AuthProvider mounted');
    checkAuthStatus();
  }, [checkAuthStatus]);

  const login = useCallback((token, refreshToken, userData) => {
    console.log('Logging in...');
    localStorage.setItem('authToken', token);
    localStorage.setItem('refreshToken', refreshToken);
    setIsAuthenticated(true);
    setUser(userData);
    console.log('Login complete');
  }, []);

  const logout = useCallback(() => {
    console.log('Logging out...');
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    setIsAuthenticated(false);
    setUser(null);
    console.log('Logout complete');
  }, []);

  const refreshToken = useCallback(async () => {
    console.log('Refreshing token...');
    try {
      const api = getApi();
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        throw new Error('No refresh token available');
      }
      const response = await api.refreshToken(refreshToken);
      if (response.success) {
        console.log('Token refreshed successfully');
        localStorage.setItem('authToken', response.token);
        setIsAuthenticated(true);
        setUser(response.user);
        return true;
      } else {
        throw new Error('Failed to refresh token');
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout();
      return false;
    }
  }, [logout]);

  /**
   * Update user information in the context
   * @param {Object} updatedUserData - The updated user data
   */
  const updateUser = useCallback((updatedUserData) => {
    setUser(prevUser => ({
      ...prevUser,
      ...updatedUserData,
      nickname: updatedUserData.nickname || prevUser?.nickname,
      team_number: updatedUserData.team_number || prevUser?.team_number,
      role: updatedUserData.role || prevUser?.role,
      graduating_year: updatedUserData.graduating_year || prevUser?.graduating_year,
      student_name: updatedUserData.student_name || prevUser?.student_name,
    }));
  }, []);

  const value = {
    isAuthenticated,
    user,
    loading,
    login,
    logout,
    refreshToken,
    checkAuthStatus,
    updateUser
  };

  console.log('AuthProvider rendering. isAuthenticated:', isAuthenticated, 'loading:', loading);

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;