import React, { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { getAdditionalRPInfo, getTBAMatchUrl } from '../utils/eventUtils';
import TeamCell from './TeamCell';

const styles = {
  redAlliance: {
    backgroundColor: '#8B0000',
  },
  blueAlliance: {
    backgroundColor: '#00008B',
  },
  redAllianceLight: {
    backgroundColor: '#a41818',
  },
  blueAllianceLight: {
    backgroundColor: '#110eb4',
  },
  winningScore: {
    border: '2px solid white',
  },
  mobileMatchNumberCell: {
    width: '30px',
    maxWidth: '30px',
    padding: '4px',
    textAlign: 'center',
  },
};

/**
 * Formats the date as day of the week
 * @param {number} timestamp - The Unix timestamp from the API
 * @param {boolean} isMobile - Whether the current view is mobile
 * @returns {string} Formatted day of the week (e.g., 'Fri' for mobile, 'Friday' for non-mobile)
 */
const formatDayOfWeek = (timestamp, isMobile) => {
  if (!timestamp) return 'TBA';
  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
  return date.toLocaleString('en-US', { weekday: isMobile ? 'short' : 'long' });
};

/**
 * Formats the time
 * @param {number} timestamp - The Unix timestamp from the API
 * @param {boolean} isMobile - Whether the current view is mobile
 * @returns {string} Formatted time (e.g., '14:30' for mobile, '2:30 PM' for non-mobile)
 */
const formatTime = (timestamp, isMobile) => {
  if (!timestamp) return 'TBA';
  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
  return date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: !isMobile
  });
};

/**
 * Sorts matches by competition level and match number
 * @param {Array} matches - Array of match objects to be sorted
 * @returns {Array} Sorted array of match objects
 */
const sortMatches = (matches) => {
  const compLevelOrder = { qm: 0, ef: 1, qf: 2, sf: 3, f: 4 };
  
  return matches.sort((a, b) => {
    if (a.comp_level !== b.comp_level) {
      return compLevelOrder[a.comp_level] - compLevelOrder[b.comp_level];
    }
    if (a.set_number !== b.set_number) {
      return a.set_number - b.set_number;
    }
    return a.match_number - b.match_number;
  });
};

/**
 * Determines the match level group for a given match
 * @param {Object} match - The match object
 * @returns {string} The match level group (Quals, Elims, or Finals)
 */
const getMatchLevelGroup = (match) => {
  switch (match.comp_level) {
    case 'qm':
      return 'Quals';
    case 'ef':
    case 'qf':
    case 'sf':
      return 'Elims';
    case 'f':
      return 'Finals';
    default:
      return 'Other';
  }
};

/**
 * Renders a separator row between different match levels
 * @param {string} levelName - The name of the match level
 * @param {number} colSpan - The number of columns the separator should span
 * @returns {JSX.Element} The rendered separator row
 */
const SeparatorRow = ({ levelName, colSpan }) => {
  const theme = useTheme();
  return (
    <TableRow>
      <TableCell 
        colSpan={colSpan} 
        sx={{
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          fontWeight: 'bold',
          textAlign: 'center',
          borderBottom: `1px solid ${theme.palette.divider}`,
          py: 1,
        }}
      >
        <Typography variant="subtitle1">
          {levelName}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const MatchScheduleTable = ({ matches, teams, eventKey, onTeamClick, onOpenScoutingData, highlightedTeam, scoutingData, fullWidth }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Sort the matches
  const sortedMatches = sortMatches(matches);

  useEffect(() => {
    console.log('MatchScheduleTable received props:', { 
      matchesCount: matches.length, 
      teamsCount: teams.length, 
      eventKey, 
      onTeamClick: !!onTeamClick, 
      onOpenScoutingData: !!onOpenScoutingData, 
      highlightedTeam, 
      scoutingDataKeys: Object.keys(scoutingData || {}).length,
      fullWidth
    });
  }, [matches, teams, eventKey, onTeamClick, onOpenScoutingData, highlightedTeam, scoutingData, fullWidth]);

  const getMatchDisplay = (match) => {
    if (!match) return '';
    if (isMobile) {
      switch (match.comp_level) {
        case 'qm':
          return `${match.match_number}`;
        case 'qf':
          return `Q${match.set_number}`;
        case 'sf':
          return `M${match.set_number}`;
        case 'f':
          return `F${match.match_number}`;
        default:
          return `${match.comp_level}${match.match_number}`;
      }
    } else {
      switch (match.comp_level) {
        case 'qm':
          return `Qual ${match.match_number}`;
        case 'qf':
          return `QF ${match.set_number}${match.match_number > 1 ? `-${match.match_number}` : ''}`;
        case 'sf':
          return `Match ${match.set_number}${match.match_number > 1 ? `-${match.match_number}` : ''}`;
        case 'f':
          return `Final ${match.match_number}`;
        default:
          return `${match.comp_level.toUpperCase()} ${match.match_number}`;
      }
    }
  };

  const hasScoutingData = (matchKey, teamKey) => {
    const key = `${matchKey}-${teamKey.replace('frc', '')}`;
    const hasData = !!scoutingData[key];
    console.log(`Checking scouting data for ${key}: ${hasData}`);
    return hasData;
  };

  const handleTeamClick = (e, teamKey, match) => {
    console.log('MatchScheduleTable handleTeamClick called:', { teamKey, matchKey: match.key });
    if (onTeamClick) {
      onTeamClick(teamKey, match);
    } else {
      console.error('onTeamClick is not a function');
    }
  };

  /**
   * Renders the score or scheduled day for a match in the red alliance column
   * @param {Object} match - The match object
   * @returns {JSX.Element} The rendered cell content
   */
  const renderRedScoreOrDay = (match) => {
    const score = match.alliances.red.score;
    const rpInfo = getAdditionalRPInfo(match, 'red');
    
    if (score >= 0) {
      return (
        <Tooltip title={rpInfo.reason} arrow>
          <span>
            {'*'.repeat(rpInfo.count)}
            {score}
          </span>
        </Tooltip>
      );
    } else {
      const scheduledTime = match.predicted_time || match.time;
      return (
        <Tooltip title="Scheduled Day" arrow>
          <span>{formatDayOfWeek(scheduledTime, isMobile)}</span>
        </Tooltip>
      );
    }
  };

  /**
   * Renders the score or scheduled time for a match in the blue alliance column
   * @param {Object} match - The match object
   * @returns {JSX.Element} The rendered cell content
   */
  const renderBlueScoreOrTime = (match) => {
    const score = match.alliances.blue.score;
    const rpInfo = getAdditionalRPInfo(match, 'blue');
    
    if (score >= 0) {
      return (
        <Tooltip title={rpInfo.reason} arrow>
          <span>
            {'*'.repeat(rpInfo.count)}
            {score}
          </span>
        </Tooltip>
      );
    } else {
      const scheduledTime = match.predicted_time || match.time;
      return (
        <Tooltip title="Scheduled Time" arrow>
          <span>{formatTime(scheduledTime, isMobile)}</span>
        </Tooltip>
      );
    }
  };

  // Group matches by their level
  const matchGroups = sortedMatches.reduce((groups, match) => {
    const group = getMatchLevelGroup(match);
    if (!groups[group]) {
      groups[group] = [];
    }
    groups[group].push(match);
    return groups;
  }, {});

  return (
    <TableContainer 
      component={Paper} 
      sx={{ 
        width: fullWidth && isMobile ? '100vw' : '100%',
        margin: fullWidth && isMobile ? '0 -16px' : '0',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={isMobile ? styles.mobileMatchNumberCell : {}}>
              {isMobile ? '#' : 'Match'}
            </TableCell>
            <TableCell align="center" colSpan={3} style={styles.redAlliance}>Red Alliance</TableCell>
            <TableCell align="center" style={styles.redAlliance}>Red Score</TableCell>
            <TableCell align="center" style={styles.blueAlliance}>Blue Score</TableCell>
            <TableCell align="center" colSpan={3} style={styles.blueAlliance}>Blue Alliance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(matchGroups).map(([group, groupMatches]) => (
            <React.Fragment key={group}>
              {group !== 'Quals' && <SeparatorRow levelName={group} colSpan={9} />}
              {groupMatches.map((match, index) => {
                const redWon = match.winning_alliance === 'red';
                const blueWon = match.winning_alliance === 'blue';
                const isAlternateRow = index % 2 !== 0;
                const redStyle = isAlternateRow ? styles.redAllianceLight : styles.redAlliance;
                const blueStyle = isAlternateRow ? styles.blueAllianceLight : styles.blueAlliance;
                
                return (
                  <TableRow key={match.key}>
                    <TableCell component="th" scope="row" style={isMobile ? styles.mobileMatchNumberCell : {}}>
                      <Link 
                        href={getTBAMatchUrl(match)} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        color="inherit"
                      >
                        <Typography variant={isMobile ? "caption" : "body2"}>
                          {getMatchDisplay(match)}
                        </Typography>
                      </Link>
                    </TableCell>
                    {match.alliances.red.team_keys.map(team => (
                      <TeamCell
                        key={team}
                        teamKey={team}
                        allianceStyle={redStyle}
                        match={match}
                        teams={teams}
                        highlightedTeam={highlightedTeam}
                        onTeamClick={handleTeamClick}
                        onOpenScoutingData={onOpenScoutingData}
                        hasScoutingData={hasScoutingData(match.key, team)}
                      />
                    ))}
                    <TableCell align="center" style={{
                      ...redStyle,
                      ...(redWon && styles.winningScore)
                    }}>
                      {renderRedScoreOrDay(match)}
                    </TableCell>
                    <TableCell align="center" style={{
                      ...blueStyle,
                      ...(blueWon && styles.winningScore)
                    }}>
                      {renderBlueScoreOrTime(match)}
                    </TableCell>
                    {match.alliances.blue.team_keys.map(team => (
                      <TeamCell
                        key={team}
                        teamKey={team}
                        allianceStyle={blueStyle}
                        match={match}
                        teams={teams}
                        highlightedTeam={highlightedTeam}
                        onTeamClick={handleTeamClick}
                        onOpenScoutingData={onOpenScoutingData}
                        hasScoutingData={hasScoutingData(match.key, team)}
                      />
                    ))}
                  </TableRow>
                );
              })}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MatchScheduleTable;