import React, { useState, useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box
} from '@mui/material';
import createApi from '../services/api';

const api = createApi();

const EventAnalyticsReportsPreview = ({ teamNumber, eventKey }) => {
  const [scoutingData, setScoutingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchScoutingData = async () => {
      try {
        setLoading(true);
        const data = await api.fetchScoutingDataForTeamAtEvent(teamNumber, eventKey);
        setScoutingData(data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch scouting data');
        setLoading(false);
      }
    };

    if (teamNumber && eventKey) {
      fetchScoutingData();
    }
  }, [teamNumber, eventKey]);

  const renderMetricValue = (value) => {
    if (value === null || value === undefined) {
      return '-';
    }
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    if (typeof value === 'object') {
      if ('made' in value && 'missed' in value) {
        return `${value.made} / ${value.missed}`;
      }
      return JSON.stringify(value);
    }
    return value.toString();
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (scoutingData.length === 0) {
    return <Typography>No scouting data available for this team at this event.</Typography>;
  }

  const metrics = Object.keys(scoutingData[0]).filter(key => key !== 'match_number');

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Scouting Data for Team {teamNumber}
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Match</TableCell>
              {metrics.map((metric) => (
                <TableCell key={metric}>{metric}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {scoutingData.map((entry, index) => (
              <TableRow key={index}>
                <TableCell>{entry.match_number}</TableCell>
                {metrics.map((metric) => (
                  <TableCell key={metric}>{renderMetricValue(entry[metric])}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EventAnalyticsReportsPreview;