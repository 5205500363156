import React from 'react';
import { 
  List, 
  ListItem, 
  ListItemText, 
  Typography, 
  Alert 
} from '@mui/material';

const TeamsList = ({ teams }) => {
  if (teams.length === 0) {
    return <Alert severity="info">No teams are currently available for this event.</Alert>;
  }

  // Sort teams numerically by team number
  const sortedTeams = [...teams].sort((a, b) => a.team_number - b.team_number);

  return (
    <List>
      {sortedTeams.map(team => (
        <ListItem key={team.key}>
          <ListItemText
            primary={`${team.team_number} - ${team.nickname}`}
            secondary={
              <>
                {team.school_name && <Typography component="span" variant="body2" color="textPrimary">
                  {team.school_name}
                </Typography>}
                <br />
                {`${team.city}, ${team.state_prov}, ${team.country}`}
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default TeamsList;