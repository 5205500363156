import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Typography, 
  CircularProgress, 
  Alert, 
  Tabs, 
  Tab, 
  Box, 
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import MatchSchedule from './MatchSchedule';
import RankingTable from './RankingTable';
import TeamsList from './TeamsList';
import ElimsBracket from './ElimsBracket';
import { createApi } from '../services/api';

// TabPanel component remains unchanged
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: { xs: 1, sm: 2, md: 3 } }}>{children}</Box>}
    </div>
  );
}

/**
 * Truncates the event title by removing phrases like "presented by X".
 * @param {string} title - The original event title.
 * @returns {string} The truncated event title.
 */
const truncateEventTitle = (title) => {
  return title.replace(/\s+(presented|sponsored|hosted)\s+by\s+.*/i, '').trim();
};

function EventDetails() {
  const { eventKey } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [eventData, setEventData] = useState(null);
  const [matches, setMatches] = useState([]);
  const [teams, setTeams] = useState([]);
  const [rankings, setRankings] = useState(null);
  const [alliances, setAlliances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [highlightedTeam, setHighlightedTeam] = useState('2102');
  const [refreshScoutingData, setRefreshScoutingData] = useState(false);

  const api = createApi();

  const fetchEventData = useCallback(async () => {
    if (!eventKey) {
      setError('No event key provided');
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);
      if (!api) {
        throw new Error('Failed to initialize API');
      }
      console.log('Fetching event details for eventKey:', eventKey);
      const eventDetails = await api.fetchEventDetails(eventKey);
      console.log('Fetched event details:', eventDetails);

      console.log('Fetching event matches');
      const eventMatches = await api.fetchEventMatches(eventKey);
      console.log('Fetched event matches:', eventMatches);

      console.log('Fetching event teams');
      const eventTeams = await api.fetchEventTeams(eventKey);
      console.log('Fetched event teams:', eventTeams);

      console.log('Fetching event rankings');
      const eventRankings = await api.fetchEventRankings(eventKey);
      console.log('Fetched event rankings:', eventRankings);

      setEventData(eventDetails);
      setMatches(sortMatches(eventMatches));
      setTeams(eventTeams);
      setRankings(eventRankings);

      // Fetch alliance data for events from 2024 onwards
      if (new Date(eventDetails.start_date).getFullYear() >= 2024) {
        try {
          console.log('Fetching event alliances');
          const eventAlliances = await api.fetchEventAlliances(eventKey);
          console.log('Fetched event alliances:', eventAlliances);
          setAlliances(eventAlliances);
        } catch (allianceError) {
          console.error('Error fetching alliance data:', allianceError);
          // Fallback to deriving alliances from matches if the API call fails
          console.log('Falling back to deriving alliances from matches');
          const derivedAlliances = deriveAlliancesFromMatches(eventMatches);
          console.log('Derived alliances:', derivedAlliances);
          setAlliances(derivedAlliances);
        }
      } else {
        console.log('Event is before 2024, not fetching alliances');
      }
    } catch (err) {
      console.error('Error fetching event data:', err);
      setError('Failed to load event data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [eventKey, api]);

  useEffect(() => {
    fetchEventData();
  }, [fetchEventData]);

  // Existing helper functions remain unchanged
  const sortMatches = (matches) => {
    const matchOrder = { qm: 1, qf: 2, sf: 3, f: 4 };
    return matches.sort((a, b) => {
      const aType = a.comp_level;
      const bType = b.comp_level;
      if (matchOrder[aType] !== matchOrder[bType]) {
        return matchOrder[aType] - matchOrder[bType];
      }
      return a.match_number - b.match_number;
    });
  };

  const deriveAlliancesFromMatches = (matches) => {
    const elimMatches = matches.filter(match => match.comp_level !== 'qm');
    const allianceMap = new Map();

    elimMatches.forEach(match => {
      ['red', 'blue'].forEach(color => {
        const allianceNumber = match.alliances[color].alliance_number;
        if (allianceNumber && !allianceMap.has(allianceNumber)) {
          allianceMap.set(allianceNumber, {
            name: `Alliance ${allianceNumber}`,
            picks: match.alliances[color].team_keys
          });
        }
      });
    });

    return Array.from(allianceMap.values());
  };

  const handleTeamClick = (teamKey, match) => {
    console.log('handleTeamClick called with teamKey:', teamKey, 'and match:', match);
    console.log('teamKey type:', typeof teamKey);
    console.log('teamKey value:', teamKey);

    if (!teamKey || typeof teamKey !== 'string') {
      console.error('Invalid teamKey:', teamKey);
      return;
    }

    const teamNumber = teamKey.startsWith('frc') ? teamKey.slice(3) : teamKey;
    console.log('Team clicked:', teamNumber);
    console.log('Match:', match.key);

    const alliance = match.alliances.red.team_keys.includes(teamKey) ? 'red' : 'blue';
    const position = match.alliances[alliance].team_keys.indexOf(teamKey) + 1;

    console.log('Navigating to scouting form');
    const scoutingFormPath = `/events/${eventKey}/${teamKey}/${match.key}`;
    console.log('Scouting form path:', scoutingFormPath);
    navigate(scoutingFormPath, {
      state: { 
        matchData: match,
        alliance: { color: alliance, position: position }
      }
    });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleHighlightedTeamChange = (event) => {
    setHighlightedTeam(event.target.value);
  };

  const filterMatchesForHighlightedTeam = (matches, teamNumber) => {
    return matches.filter(match => {
      const allTeams = [
        ...match.alliances.red.team_keys,
        ...match.alliances.blue.team_keys
      ];
      return allTeams.includes(`frc${teamNumber}`);
    });
  };

  const handleScoutingDataSubmit = useCallback(() => {
    console.log('Scouting data submitted, refreshing data');
    setRefreshScoutingData(prev => !prev);
    setTabValue(0); // Return to the Matches tab after submission
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!eventData) {
    return null;
  }

  const ourMatches = filterMatchesForHighlightedTeam(matches, highlightedTeam);
  const showElimsBracket = eventData && new Date(eventData.start_date).getFullYear() >= 2024;

  console.log('Rendering EventDetails component');
  console.log('showElimsBracket:', showElimsBracket);
  console.log('alliances:', alliances);

  return (
    <Box sx={{ 
      width: '100%',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: { xs: 1, sm: 2, md: 3 },
    }}>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between', 
        alignItems: { xs: 'flex-start', sm: 'center' }, 
        mb: 2,
        gap: 2,
      }}>
        <Typography variant="h4" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
          {truncateEventTitle(eventData.name)}
        </Typography>
        <FormControl sx={{ minWidth: 120, width: { xs: '100%', sm: 'auto' } }}>
          <InputLabel id="highlighted-team-label">Highlight Team</InputLabel>
          <Select
            labelId="highlighted-team-label"
            id="highlighted-team-select"
            value={highlightedTeam}
            label="Highlight Team"
            onChange={handleHighlightedTeamChange}
          >
            {teams.map((team) => (
              <MenuItem key={team.key} value={team.team_number}>
                {team.team_number}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ 
        borderBottom: 1, 
        borderColor: 'divider', 
        display: 'flex', 
        justifyContent: 'center',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
      }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          aria-label="event details tabs"
          variant={isMobile ? "scrollable" : "standard"}
          scrollButtons={isMobile ? "auto" : false}
          sx={{
            '& .MuiTab-root': {
              minWidth: { xs: 'auto', sm: 120 },
              padding: { xs: '6px 12px', sm: '12px 16px' },
            },
          }}
        >
          <Tab label="Matches" />
          <Tab label="Our Matches" />
          <Tab label="Rankings" />
          <Tab label="Teams" />
          {showElimsBracket && <Tab label="Elims" />}
        </Tabs>
        {!showElimsBracket && (
          <Tooltip title="Elimination bracket is only available for events from 2024 onwards" arrow>
            <IconButton size="small" sx={{ ml: 1 }}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <TabPanel value={tabValue} index={0}>
        <MatchSchedule 
          matches={matches} 
          teams={teams} 
          eventKey={eventKey} 
          onTeamClick={handleTeamClick}
          highlightedTeam={highlightedTeam}
          onScoutingDataSubmit={handleScoutingDataSubmit}
          refreshTrigger={refreshScoutingData}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <MatchSchedule 
          matches={ourMatches} 
          teams={teams} 
          eventKey={eventKey} 
          onTeamClick={handleTeamClick}
          highlightedTeam={highlightedTeam}
          onScoutingDataSubmit={handleScoutingDataSubmit}
          refreshTrigger={refreshScoutingData}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <RankingTable rankingData={rankings} highlightedTeam={highlightedTeam} highlightColor="#FFC107" />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <TeamsList teams={teams} onTeamClick={handleTeamClick} />
      </TabPanel>
      {showElimsBracket && (
        <TabPanel value={tabValue} index={4}>
          <ElimsBracket matches={matches} teams={teams} eventKey={eventKey} alliances={alliances} />
        </TabPanel>
      )}
    </Box>
  );
}

export default EventDetails;