import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Divider,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Alert,
  Box,
  LinearProgress,
  CircularProgress,
  Slider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import createApi from '../services/api';

const styles = {
  dialogContent: {
    padding: '20px',
  },
  dataItem: {
    marginBottom: '5px',
  },
  progressBar: {
    height: 10,
    borderRadius: 5,
  },
  madeText: {
    color: '#4caf50',
    fontWeight: 'bold',
  },
  missedText: {
    color: '#ff7f7f',
    fontWeight: 'bold',
  },
};

/**
 * ScoutingDataDialog component displays scouting data for a team in a specific match.
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.open - Controls whether the dialog is open
 * @param {function} props.onClose - Function to call when closing the dialog
 * @param {Array} props.scoutingData - Array of scouting data objects
 * @param {Object} props.match - Match object containing match details
 * @param {function} props.getMatchDisplay - Function to get the display string for a match
 * @param {string} props.apiUrl - API URL for fetching scouting configuration
 */
const ScoutingDataDialog = ({ open, onClose, scoutingData, match, getMatchDisplay, apiUrl }) => {
  const [scoutingConfig, setScoutingConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const api = createApi(apiUrl);

  useEffect(() => {
    const fetchScoutingConfig = async () => {
      try {
        const currentYear = new Date().getFullYear().toString();
        const config = await api.getScoutingConfig(currentYear);
        setScoutingConfig(config);
        console.log('Fetched scouting config:', config);
      } catch (error) {
        console.error('Error fetching scouting configuration:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchScoutingConfig();
  }, [api]);

  /**
   * Renders the value of a scouting data field based on its type and key.
   * 
   * @param {string} key - The key of the scouting data field
   * @param {any} value - The value of the scouting data field
   * @param {Object} metricConfig - The configuration for this metric
   * @returns {React.ReactNode} - Rendered representation of the value
   */
  const renderValue = (key, value, metricConfig) => {
    console.log(`Rendering value for key: ${key}, value: ${value}, metricConfig:`, metricConfig);

    if (typeof value === 'boolean') {
      return (
        <Chip 
          label={value ? 'Yes' : 'No'} 
          color={value ? 'success' : 'error'} 
          size="small" 
        />
      );
    }
    if (typeof value === 'object' && value !== null) {
      if ('made' in value && 'missed' in value) {
        const total = value.made + value.missed;
        const percentage = total > 0 ? (value.made / total) * 100 : 0;
        return (
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2" style={styles.madeText}>Made: {value.made}</Typography>
              <Typography variant="body2" style={styles.missedText}>Missed: {value.missed}</Typography>
            </Box>
            <LinearProgress 
              variant="determinate" 
              value={percentage} 
              style={styles.progressBar}
              sx={{
                backgroundColor: '#ff7f7f',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#4caf50',
                },
              }}
            />
          </Box>
        );
      }
      return JSON.stringify(value);
    }
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    if (key.toLowerCase().includes('comment')) {
      return (
        <Typography variant="body2" style={{whiteSpace: 'pre-wrap'}}>
          {value}
        </Typography>
      );
    }
    if (metricConfig && metricConfig.type === 'timer') {
      const maxTime = metricConfig.maxTime || 150;
      const percentage = (value / maxTime) * 100;
      return (
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2">{value} sec</Typography>
            <Typography variant="body2">/ {maxTime} sec</Typography>
          </Box>
          <LinearProgress 
            variant="determinate" 
            value={percentage} 
            style={styles.progressBar}
            sx={{
              backgroundColor: '#e0e0e0',
              '& .MuiLinearProgress-bar': {
                backgroundColor: metricConfig.buttonColors?.running || '#2196f3',
              },
            }}
          />
        </Box>
      );
    }
    // Range metric
    if (metricConfig && metricConfig.type === 'range') {
      const min = metricConfig.min || 0;
      const max = metricConfig.max || 100;
      return (
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2">{value}</Typography>
            <Typography variant="body2">Range: {min} - {max}</Typography>
          </Box>
          <Slider
            value={value}
            min={min}
            max={max}
            disabled
            sx={{
              '& .MuiSlider-thumb': {
                color: '#2196f3',
              },
              '& .MuiSlider-track': {
                color: '#2196f3',
              },
              '& .MuiSlider-rail': {
                color: '#e0e0e0',
              },
            }}
          />
        </Box>
      );
    }
    // Multiple choice or attempted metric
    if (metricConfig && (metricConfig.type === 'multipleChoice' || metricConfig.type === 'attempted')) {
      let displayValue = value;
      if (metricConfig.type === 'attempted') {
        const attemptedMap = {
          0: 'Didn\'t Attempt',
          1: 'Attempted but Failed',
          2: 'Succeeded'
        };
        displayValue = attemptedMap[value] || `Unknown (${value})`;
      } else if (Array.isArray(metricConfig.options)) {
        const choice = metricConfig.options.find(c => c.id === value || c.value === value);
        displayValue = choice ? choice.text : `Unknown (${value})`;
      } else if (typeof metricConfig.options === 'object') {
        displayValue = metricConfig.options[value] || `Unknown (${value})`;
      }
      console.log(`${metricConfig.type} metric: key = ${key}, value = ${value}, displayValue = ${displayValue}`);
      return (
        <Typography variant="body2" component="span">
          <span style={{ color: '#FFC107', fontWeight: 'bold' }}>{displayValue}</span>
        </Typography>
      );
    }
    // Default case for numbers and other types
    return <Typography variant="body2" fontWeight="bold">{value}</Typography>;
  };

  /**
   * Groups scouting data into categories based on the scouting configuration.
   * 
   * @param {Object} data - Scouting data object
   * @returns {Object} - Grouped scouting data
   */
  const groupData = (data) => {
    if (!scoutingConfig || !scoutingConfig.sections) {
      console.warn('Scouting configuration not available');
      return { 'Ungrouped': data };
    }

    const groups = {};
    scoutingConfig.sections.forEach(section => {
      groups[section.name] = {};
      section.metrics.forEach(metric => {
        if (data.hasOwnProperty(metric.name)) {
          groups[section.name][metric.name] = {
            value: data[metric.name],
            config: metric
          };
        }
      });
    });

    console.log('Grouped data:', groups);
    return groups;
  };

  /**
   * Renders the content of the scouting data dialog.
   * 
   * @returns {React.ReactNode} - Rendered scouting data content
   */
  const renderScoutingDataContent = () => {
    if (loading) {
      return <CircularProgress />;
    }

    if (!scoutingData || scoutingData.length === 0 || !match) return null;

    return (
      <div style={styles.dialogContent}>
        <Typography variant="h6">Team {scoutingData[0].team_key} - {getMatchDisplay(match)}</Typography>
        <Divider style={{ margin: '10px 0' }} />
        {scoutingData.map((data, index) => {
          console.log('Rendering scouting data:', data);
          let parsedData;
          try {
            parsedData = typeof data.data === 'string' ? JSON.parse(data.data) : data.data;
            console.log('Parsed scouting data:', parsedData);
          } catch (error) {
            console.error('Error parsing scouting data:', error);
            return (
              <Alert severity="error" key={index}>
                Error parsing scouting data for report {index + 1}
              </Alert>
            );
          }

          const groupedData = groupData(parsedData);

          return (
            <Accordion key={index} defaultExpanded={index === 0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Scouting Report {index + 1} - {new Date(data.created_at).toLocaleString()}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Card variant="outlined">
                  <CardContent>
                    {Object.entries(groupedData).map(([sectionName, sectionData]) => (
                      Object.keys(sectionData).length > 0 && (
                        <div key={sectionName}>
                          <Typography variant="h6" gutterBottom>{sectionName}</Typography>
                          <Grid container spacing={2}>
                            {Object.entries(sectionData).map(([key, { value, config }]) => (
                              <Grid item xs={12} sm={6} md={4} key={key}>
                                <Typography variant="subtitle2" style={styles.dataItem}>
                                  <strong>{key}:</strong> {renderValue(key, value, config)}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                          <Divider style={{ margin: '10px 0' }} />
                        </div>
                      )
                    ))}
                  </CardContent>
                </Card>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Scouting Data</DialogTitle>
      <DialogContent>
        {renderScoutingDataContent()}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScoutingDataDialog;