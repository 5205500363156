import React, { useState, useEffect, useCallback } from 'react';
import { 
  CircularProgress, 
  Alert,
} from '@mui/material';
import { createApi } from '../services/api';
import MatchScheduleTable from './MatchScheduleTable';
import ScoutingDataDialog from './ScoutingDataDialog';

const MatchSchedule = ({ matches, teams, eventKey, onTeamClick, highlightedTeam, onScoutingDataSubmit }) => {
  const [scoutingData, setScoutingData] = useState({});
  const [selectedScoutingData, setSelectedScoutingData] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const api = createApi();

  const fetchScoutingData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      console.log('Fetching scouting data for event:', eventKey);
      if (!api) {
        throw new Error('API not initialized');
      }
      const response = await api.fetchEvents(`/api/scouting-data/competition/${eventKey}`);
      console.log('Raw scouting data:', response);
      const data = response.reduce((acc, item) => {
        const key = `${item.match_key}-${item.team_key}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {});
      console.log('Processed scouting data:', data);
      setScoutingData(data);
    } catch (error) {
      console.error('Error fetching scouting data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [eventKey, api]);

  useEffect(() => {
    console.log('MatchSchedule received props:', {
      matchesCount: matches.length,
      teamsCount: teams.length,
      eventKey,
      onTeamClick: !!onTeamClick,
      highlightedTeam
    });
  }, [matches, teams, eventKey, onTeamClick, highlightedTeam]);

  useEffect(() => {
    fetchScoutingData();
  }, [fetchScoutingData]);

  useEffect(() => {
    console.log('Current scouting data state:', scoutingData);
  }, [scoutingData]);

  useEffect(() => {
    if (onScoutingDataSubmit) {
      onScoutingDataSubmit(() => fetchScoutingData());
    }
  }, [onScoutingDataSubmit, fetchScoutingData]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">Error loading scouting data: {error}</Alert>;
  }

  if (matches.length === 0) {
    return <Alert severity="info">No matches are currently available for this event.</Alert>;
  }

  const handleOpenScoutingData = (e, match, teamKey) => {
    e.preventDefault();
    e.stopPropagation();
    const data = scoutingData[`${match.key}-${teamKey}`] || [];
    console.log('Opening scouting data for:', match.key, teamKey, data);
    setSelectedScoutingData(data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
    setSelectedMatch(match);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedScoutingData([]);
    setSelectedMatch(null);
  };

  const getMatchDisplay = (match) => {
    if (!match) return '';
    switch (match.comp_level) {
      case 'qm':
        return `Qual ${match.match_number}`;
      case 'sf':
        return `SF ${match.set_number}-${match.match_number}`;
      case 'f':
        return `Final ${match.match_number}`;
      default:
        return `${match.comp_level.toUpperCase()}${match.match_number}`;
    }
  };

  const handleTeamClick = (teamKey, match) => {
    console.log('MatchSchedule handleTeamClick called:', { teamKey, matchKey: match.key });
    if (onTeamClick) {
      onTeamClick(teamKey, match);
    } else {
      console.error('onTeamClick is not a function in MatchSchedule');
    }
  };

  console.log('Scouting data being passed to MatchScheduleTable:', scoutingData);

  return (
    <>
      <MatchScheduleTable
        matches={matches}
        teams={teams}
        eventKey={eventKey}
        onTeamClick={handleTeamClick}
        onOpenScoutingData={handleOpenScoutingData}
        highlightedTeam={highlightedTeam}
        scoutingData={scoutingData}
      />
      <ScoutingDataDialog
        open={openDialog}
        onClose={handleCloseDialog}
        scoutingData={selectedScoutingData}
        match={selectedMatch}
        getMatchDisplay={getMatchDisplay}
      />
    </>
  );
};

export default MatchSchedule;