import React from 'react';
import { TableCell, Tooltip, IconButton } from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { getTeamNickname } from '../utils/eventUtils';

const styles = {
  teamNumber: {
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold',
    },
  },
  highlightedTeam: {
    backgroundColor: '#FFC107',
    fontWeight: 'bold',
  },
  scoutingIcon: {
    color: 'white',
    fontSize: '1rem',
    marginLeft: '5px',
    cursor: 'pointer',
  },
};

const TeamCell = ({ 
  teamKey, 
  allianceStyle, 
  match, 
  teams, 
  highlightedTeam, 
  onTeamClick, 
  onOpenScoutingData, 
  hasScoutingData 
}) => {
  console.log('TeamCell rendering with props:', { teamKey, match, highlightedTeam, hasScoutingData });

  const teamNumber = teamKey.replace('frc', '');
  const isHighlightedTeam = highlightedTeam && teamNumber === highlightedTeam.toString();
  const isRedAlliance = allianceStyle.backgroundColor === '#8B0000' || allianceStyle.backgroundColor === '#a41818';

  const cellStyle = {
    ...allianceStyle,
    ...(isHighlightedTeam && styles.highlightedTeam),
  };

  const textStyle = {
    ...styles.teamNumber,
    ...(isHighlightedTeam && {
      color: isRedAlliance ? '#D32F2F' : '#1976d2',
      fontWeight: 'bold',
    }),
  };

  const handleClick = (e) => {
    console.log('TeamCell clicked:', teamKey, match);
    onTeamClick(e, teamKey, match);
  };

  return (
    <TableCell align="center" style={cellStyle}>
      <Tooltip title={getTeamNickname(teamNumber, teams)} arrow>
        <span 
          style={textStyle}
          onClick={handleClick}
        >
          {teamNumber}
        </span>
      </Tooltip>
      {hasScoutingData && (
        <Tooltip title="View Scouting Data" arrow>
          <IconButton
            size="small"
            onClick={(e) => onOpenScoutingData(e, match, teamNumber)}
          >
            <AssessmentIcon style={styles.scoutingIcon} />
          </IconButton>
        </Tooltip>
      )}
    </TableCell>
  );
};

export default TeamCell;