import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Alert,
  Snackbar,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
  Rating,
  ButtonGroup,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import BlockIcon from '@mui/icons-material/Block';
import { getApi } from '../services/api';

const ColoredButton = styled(Button)(({ theme, color }) => ({
  backgroundColor: theme.palette[color].main,
  color: theme.palette[color].contrastText,
  '&:hover': {
    backgroundColor: theme.palette[color].dark,
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme, selected, color }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette[color || 'primary'].main,
    color: theme.palette[color || 'primary'].contrastText,
    '&:hover': {
      backgroundColor: theme.palette[color || 'primary'].dark,
    },
  },
}));

/**
 * CounterInput component for rendering counter type inputs
 * @param {Object} props - Component props
 * @param {Object} props.value - Current value of the counter
 * @param {Function} props.onChange - Function to handle value changes
 * @param {boolean} props.dual - Whether to show two counters (made/missed) or just one
 */
const CounterInput = ({ value, onChange, dual = true }) => {
  const renderCounter = (key, color) => (
    <ButtonGroup variant="contained" size="small">
      <ColoredButton color="error" onClick={() => onChange({ ...value, [key]: Math.max(0, value[key] - 1) })}>
        <RemoveIcon />
      </ColoredButton>
      <ColoredButton color={color} disabled style={{ opacity: 1, width: '60px' }}>
        <Typography variant="h5" style={{ fontWeight: 'bold', color: 'white' }}>
          {value[key]}
        </Typography>
      </ColoredButton>
      <ColoredButton color="success" onClick={() => onChange({ ...value, [key]: value[key] + 1 })}>
        <AddIcon />
      </ColoredButton>
    </ButtonGroup>
  );

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {dual ? (
        <>
          <Box mr={2}>
            <Typography variant="caption" display="block" textAlign="center">Made</Typography>
            {renderCounter('made', 'success')}
          </Box>
          <Box>
            <Typography variant="caption" display="block" textAlign="center">Missed</Typography>
            {renderCounter('missed', 'error')}
          </Box>
        </>
      ) : (
        renderCounter('value', 'primary')
      )}
    </Box>
  );
};

/**
 * YesNoButton component for rendering boolean type inputs
 * @param {Object} props - Component props
 * @param {boolean} props.value - Current value of the boolean
 * @param {Function} props.onChange - Function to handle value changes
 * @param {string} props.label - Label for the button
 */
const YesNoButton = ({ value, onChange, label }) => (
  <Button
    variant="contained"
    onClick={() => onChange(!value)}
    startIcon={value ? <CheckIcon /> : <CloseIcon />}
    sx={{
      backgroundColor: value ? 'success.main' : 'error.main',
      '&:hover': {
        backgroundColor: value ? 'success.dark' : 'error.dark',
      },
    }}
  >
    {label}
  </Button>
);

/**
 * TimerButton component for rendering timer type inputs
 * @param {Object} props - Component props
 * @param {number} props.value - Current value of the timer in seconds
 * @param {Function} props.onChange - Function to handle value changes
 * @param {string} props.label - Label for the button
 */
const TimerButton = ({ value, onChange, label }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        const elapsedSeconds = Math.floor((Date.now() - startTime) / 1000);
        onChange(elapsedSeconds);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning, startTime, onChange]);

  const toggleTimer = () => {
    if (isRunning) {
      setIsRunning(false);
    } else {
      setIsRunning(true);
      setStartTime(Date.now() - value * 1000);
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <Button
      variant="contained"
      onClick={toggleTimer}
      sx={{
        backgroundColor: isRunning ? 'error.light' : 'info.light',
        '&:hover': {
          backgroundColor: isRunning ? 'error.main' : 'info.main',
        },
        textTransform: 'none',
      }}
    >
      {label}: {formatTime(value)}
    </Button>
  );
};

/**
 * AttemptedInput component for rendering attempted type inputs
 * @param {Object} props - Component props
 * @param {string} props.value - Current value of the attempted input
 * @param {Function} props.onChange - Function to handle value changes
 */
const AttemptedInput = ({ value, onChange }) => (
  <ToggleButtonGroup
    value={value}
    exclusive
    onChange={(_, newValue) => onChange(newValue)}
    aria-label="attempted"
  >
    <Tooltip title="Succeeded" arrow>
      <StyledToggleButton value="succeeded" aria-label="succeeded" color="success">
        <CheckCircleIcon sx={{ fontSize: 40 }} />
      </StyledToggleButton>
    </Tooltip>
    <Tooltip title="Attempted but Failed" arrow>
      <StyledToggleButton value="failed" aria-label="failed" color="error">
        <CancelIcon sx={{ fontSize: 40 }} />
      </StyledToggleButton>
    </Tooltip>
    <Tooltip title="Didn't Attempt" arrow>
      <StyledToggleButton value="not_attempted" aria-label="not attempted" color="warning">
        <BlockIcon sx={{ fontSize: 40 }} />
      </StyledToggleButton>
    </Tooltip>
  </ToggleButtonGroup>
);

/**
 * MetricInput component for rendering different types of input fields
 * @param {Object} props - Component props
 * @param {Object} props.metric - Metric object containing type and other properties
 * @param {*} props.value - Current value of the metric
 * @param {Function} props.onChange - Function to handle value changes
 */
const MetricInput = ({ metric, value, onChange }) => {
  switch (metric.type) {
    case 'counter':
      return (
        <CounterInput
          value={value}
          onChange={onChange}
          dual={true}
        />
      );
    case 'number':
      return (
        <CounterInput
          value={{ value: value }}
          onChange={(newValue) => onChange(newValue.value)}
          dual={false}
        />
      );
    case 'boolean':
      return (
        <YesNoButton
          value={value}
          onChange={onChange}
          label={metric.name}
        />
      );
    case 'ranking':
      return (
        <Rating
          name={metric.name}
          value={value}
          onChange={(_, newValue) => onChange(newValue)}
          max={metric.max || 5}
          sx={{ fontSize: 40 }}
        />
      );
    case 'range':
      return (
        <Box sx={{ width: '80%', margin: '0 auto' }}>
          <Slider
            value={value}
            onChange={(_, newValue) => onChange(newValue)}
            valueLabelDisplay="auto"
            min={metric.min}
            max={metric.max}
            step={(metric.max - metric.min) / 20}
            marks={[
              { value: metric.min, label: metric.min.toString() },
              { value: metric.max, label: metric.max.toString() },
            ]}
          />
        </Box>
      );
    case 'multipleChoice':
      return (
        <ToggleButtonGroup
          value={value}
          exclusive
          onChange={(_, newValue) => onChange(newValue)}
          aria-label={metric.name}
        >
          {metric.options.map((option) => (
            <StyledToggleButton key={option} value={option} aria-label={option} color="info">
              {option}
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>
      );
    case 'attempted':
      return (
        <AttemptedInput
          value={value}
          onChange={onChange}
        />
      );
    case 'timer':
      return (
        <TimerButton
          value={value}
          onChange={onChange}
          label={metric.name}
        />
      );
    case 'text':
      return (
        <TextField
          label={metric.name}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          multiline
          rows={4}
          fullWidth
        />
      );
    default:
      return (
        <TextField
          label={metric.name}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      );
  }
};

/**
 * ScoutingFormSection component for displaying a section of the scouting form
 * @param {Object} props - Component props
 * @param {string} props.title - Title of the section
 * @param {Array} props.metrics - Array of metrics for this section
 * @param {Object} props.formData - Current form data
 * @param {Function} props.handleInputChange - Function to handle input changes
 */
const ScoutingFormSection = ({ title, metrics, formData, handleInputChange }) => (
  <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
    <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 'bold', mb: 2 }}>{title}</Typography>
    {metrics.map((metric) => (
      <Box key={metric.name} sx={{ my: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {metric.type !== 'boolean' && metric.type !== 'timer' && (
          <Typography variant="subtitle1" align="center">{metric.name}</Typography>
        )}
        <MetricInput
          metric={metric}
          value={formData[metric.name]}
          onChange={(value) => handleInputChange(metric.name, value)}
        />
      </Box>
    ))}
  </Paper>
);
/**
 * ScoutingPage component for displaying and managing scouting data for a specific team and match.
 * @param {Object} props - Component props
 * @param {Function} props.onScoutingDataSubmit - Callback function to be called when scouting data is submitted
 */

export default function ScoutingPage({ onScoutingDataSubmit }) {
  const { eventKey, teamKey, matchKey } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { alliance: initialAlliance, matchData: initialMatchData } = location.state || {};

  const [scoutingConfig, setScoutingConfig] = useState(null);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [teamData, setTeamData] = useState(null);
  const [matchData, setMatchData] = useState(initialMatchData);
  const [alliance, setAlliance] = useState(initialAlliance);

  const api = getApi();

  const truncateTeamName = (name) => {
    let truncated = name.replace(/^the\s+/i, '').replace(/\s+robotics$/i, '');
    return truncated.trim();
  };

  const initializeFormData = useCallback((sections) => {
    const initialData = {};
    sections.forEach(section => {
      section.metrics.forEach(metric => {
        initialData[metric.name] = 
          metric.type === 'counter' ? { made: 0, missed: 0 } : 
          metric.type === 'boolean' ? false :
          metric.type === 'ranking' ? 0 :
          metric.type === 'range' ? [metric.min, metric.max] :
          metric.type === 'multipleChoice' ? '' :
          metric.type === 'attempted' ? 'not_attempted' :
          metric.type === 'timer' ? 0 :
          metric.type === 'number' ? 0 : '';
      });
    });
    setFormData(initialData);
  }, []);

  useEffect(() => {
    const getAlliance = (match, team) => {
      if (!match || !team) return { color: 'Unknown', position: 'Unknown' };
      const redAlliance = match.alliances.red.team_keys;
      const blueAlliance = match.alliances.blue.team_keys;
      if (redAlliance.includes(team)) {
        return { color: 'Red', position: redAlliance.indexOf(team) + 1 };
      } else if (blueAlliance.includes(team)) {
        return { color: 'Blue', position: blueAlliance.indexOf(team) + 1 };
      }
      return { color: 'Unknown', position: 'Unknown' };
    };
  
    const fetchData = async () => {
      try {
        setLoading(true);
        console.log('Fetching data with params:', { eventKey, teamKey, matchKey });

        if (!eventKey || !teamKey || !matchKey) {
          throw new Error('Missing required parameters');
        }

        const year = eventKey.substring(0, 4);
        console.log('Extracted year:', year);

        const teamNumber = teamKey.replace('frc', '');
        console.log('Extracted team number:', teamNumber);

        if (!teamNumber) {
          throw new Error('Invalid team key');
        }

        console.log('Making API calls...');
        const [configResponse, teamResponse, matchResponse] = await Promise.all([
          api.getScoutingConfig(year),
          api.getTeamData(teamNumber),
          matchData ? Promise.resolve(matchData) : api.getMatchData(matchKey)
        ]);

        console.log('API responses:', {
          config: configResponse,
          team: teamResponse,
          match: matchResponse
        });

        setScoutingConfig(configResponse.sections);
        setTeamData(teamResponse);
        setMatchData(matchResponse);

        const newAlliance = getAlliance(matchResponse, teamKey);
        console.log('Setting alliance:', newAlliance);
        setAlliance(newAlliance);

        initializeFormData(configResponse.sections);
      } catch (error) {
        console.error('Error in fetchData:', error);
        setError(`Failed to load necessary data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [eventKey, teamKey, matchKey, matchData, api, initializeFormData]);


  const handleInputChange = useCallback((metricName, value) => {
    setFormData(prevData => ({
      ...prevData,
      [metricName]: value
    }));
  }, []);

  const goToNextMatch = useCallback(async () => {
    console.log('goToNextMatch function called');
    console.log('Current state:', { eventKey, matchKey, alliance });
    try {
      console.log('goToNextMatch called with:', { eventKey, matchKey, alliance });
      
      if (!eventKey || !matchKey || !alliance) {
        console.error('Missing required parameters for goToNextMatch');
        setSnackbar({ open: true, message: 'Error: Missing required parameters for next match', severity: 'error' });
        return;
      }

      const params = {
        eventKey,
        currentMatchKey: matchKey,
        alliance: alliance.color.toLowerCase(),
        position: alliance.position.toString()
      };
      console.log('Fetching next match with params:', JSON.stringify(params, null, 2));

      const nextMatch = await api.getNextMatch(params);
      console.log('Received next match data:', JSON.stringify(nextMatch, null, 2));
      
      if (!nextMatch) {
        console.log('No next match found');
        setSnackbar({ open: true, message: 'No more matches found for this team', severity: 'info' });
        return;
      }

      console.log('Navigating to next match:', `/scout/${nextMatch.eventKey}/${nextMatch.teamKey}/${nextMatch.matchKey}`);
      navigate(`/scout/${nextMatch.eventKey}/${nextMatch.teamKey}/${nextMatch.matchKey}`, {
        state: { 
          alliance: {
            color: nextMatch.alliance,
            position: parseInt(nextMatch.position, 10)
          }
        }
      });
    } catch (error) {
      console.error('Error fetching next match:', error);
      setSnackbar({ open: true, message: `Error fetching next match: ${error.message}`, severity: 'error' });
    }
  }, [eventKey, matchKey, alliance, api, navigate]);

  const goToMatchList = useCallback(() => {
    navigate(`/events/${eventKey}`);
  }, [navigate, eventKey]);

  const handleSubmit = useCallback(async (e, shouldClose = false) => {
    e.preventDefault();
    console.log('handleSubmit function called');
    console.log('Current state before submission:', { eventKey, matchKey, teamKey, alliance });
    try {
      console.log('Submitting scouting data:', {
        eventKey,
        matchKey,
        teamKey,
        scoutingData: formData
      });
      const response = await api.submitScoutingData({
        eventKey,
        matchKey,
        teamKey,
        scoutingData: formData
      });
      console.log('Scouting data submitted successfully:', response);
      setSnackbar({ open: true, message: 'Scouting data submitted successfully', severity: 'success' });

      if (onScoutingDataSubmit) {
        onScoutingDataSubmit(eventKey, matchKey, teamKey);
      }

      if (shouldClose) {
        setTimeout(() => {
          goToMatchList();
        }, 1000);
      } else {
        console.log('Calling goToNextMatch');
        console.log('Current state before goToNextMatch:', { eventKey, matchKey, alliance });
        await goToNextMatch();
      }
    } catch (error) {
      console.error('Error submitting scouting data:', error.response ? error.response.data : error.message);
      setSnackbar({ open: true, message: 'Error submitting scouting data', severity: 'error' });
    }
  }, [eventKey, matchKey, teamKey, alliance, formData, api, onScoutingDataSubmit, goToMatchList, goToNextMatch]);

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  const allianceColor = alliance?.color === 'Red' ? '#8B0000' : '#00008B';
  const matchIdentifier = matchData?.comp_level === 'qm' ? `Q${matchData.match_number}` : matchData?.key.split('_')[1];

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Paper elevation={3} sx={{ p: 2, mb: 2, bgcolor: allianceColor }}>
        <Typography variant="h5" align="center" sx={{ color: 'white' }}>
          {matchIdentifier} - {teamData?.team_number} {truncateTeamName(teamData?.nickname || '')}
        </Typography>
      </Paper>
      <Grid container spacing={2}>
        {scoutingConfig && scoutingConfig.map((section, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ScoutingFormSection
              title={section.name}
              metrics={section.metrics}
              formData={formData}
              handleInputChange={handleInputChange}
            />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button variant="contained" color="secondary" onClick={(e) => handleSubmit(e, true)}>
          Submit and Close
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit and Next
        </Button>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}