import React, { useState } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Alert,
  useMediaQuery,
  useTheme,
  Typography,
  Box
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const RankingTable = ({ rankingData, highlightedTeam, highlightColor = "#FFC107" }) => {
  const [sortColumn, setSortColumn] = useState('rank');
  const [sortDirection, setSortDirection] = useState('asc');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!rankingData || !rankingData.rankings || rankingData.rankings.length === 0) {
    return <Alert severity="info">No ranking data is currently available for this event. This might be because the event hasn't started yet or rankings haven't been published.</Alert>;
  }

  if (!Array.isArray(rankingData.sort_order_info) || !Array.isArray(rankingData.extra_stats_info)) {
    console.error('Invalid ranking data structure:', rankingData);
    return <Alert severity="error">Error: Ranking data is in an unexpected format. Please try again later.</Alert>;
  }

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedData = [...rankingData.rankings].sort((a, b) => {
    let aValue = a[sortColumn];
    let bValue = b[sortColumn];
    
    if (sortColumn === 'team_key') {
      aValue = parseInt(a.team_key.slice(3));
      bValue = parseInt(b.team_key.slice(3));
    } else if (sortColumn.startsWith('sort_orders')) {
      const index = parseInt(sortColumn.split('_')[2]);
      aValue = a.sort_orders && a.sort_orders[index];
      bValue = b.sort_orders && b.sort_orders[index];
    }

    if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  const renderSortArrow = (column) => {
    if (sortColumn === column) {
      return sortDirection === 'asc' ? 
        <ArrowUpwardIcon fontSize="small" /> : 
        <ArrowDownwardIcon fontSize="small" />;
    }
    return null;
  };

  const getVisibleColumns = () => {
    if (isMobile) {
      return ['team_key', 'rank', 'sort_orders_0']; // Show only team number, rank, and first sort order on mobile
    }
    return [
      'team_key',
      'rank',
      ...rankingData.sort_order_info.map((_, index) => `sort_orders_${index}`),
      ...rankingData.extra_stats_info.map((_, index) => `extra_stats_${index}`)
    ];
  };

  const visibleColumns = getVisibleColumns();

  return (
    <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            {visibleColumns.includes('team_key') && (
              <TableCell onClick={() => handleSort('team_key')} sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                <Box display="flex" alignItems="center">
                  <Typography variant={isMobile ? "body2" : "body1"}>Team Number</Typography>
                  {renderSortArrow('team_key')}
                </Box>
              </TableCell>
            )}
            {visibleColumns.includes('rank') && (
              <TableCell onClick={() => handleSort('rank')} sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                <Box display="flex" alignItems="center">
                  <Typography variant={isMobile ? "body2" : "body1"}>Rank</Typography>
                  {renderSortArrow('rank')}
                </Box>
              </TableCell>
            )}
            {rankingData.sort_order_info.map((info, index) => {
              const column = `sort_orders_${index}`;
              if (visibleColumns.includes(column)) {
                return (
                  <TableCell key={info.name} onClick={() => handleSort(column)} sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                    <Box display="flex" alignItems="center">
                      <Typography variant={isMobile ? "body2" : "body1"}>{info.name}</Typography>
                      {renderSortArrow(column)}
                    </Box>
                  </TableCell>
                );
              }
              return null;
            })}
            {rankingData.extra_stats_info.map((info, index) => {
              const column = `extra_stats_${index}`;
              if (visibleColumns.includes(column)) {
                return (
                  <TableCell key={info.name} onClick={() => handleSort(column)} sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                    <Box display="flex" alignItems="center">
                      <Typography variant={isMobile ? "body2" : "body1"}>{info.name}</Typography>
                      {renderSortArrow(column)}
                    </Box>
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((team) => {
            const teamNumber = team.team_key ? team.team_key.slice(3) : 'N/A';
            const isHighlighted = teamNumber === highlightedTeam;
            return (
              <TableRow key={team.team_key}>
                {visibleColumns.includes('team_key') && (
                  <TableCell sx={{ color: isHighlighted ? highlightColor : 'inherit', whiteSpace: 'nowrap' }}>
                    <Typography variant={isMobile ? "body2" : "body1"}>{teamNumber}</Typography>
                  </TableCell>
                )}
                {visibleColumns.includes('rank') && (
                  <TableCell>
                    <Typography variant={isMobile ? "body2" : "body1"}>{team.rank || 'N/A'}</Typography>
                  </TableCell>
                )}
                {rankingData.sort_order_info.map((info, index) => {
                  const column = `sort_orders_${index}`;
                  if (visibleColumns.includes(column)) {
                    return (
                      <TableCell key={`sort_${index}`}>
                        <Typography variant={isMobile ? "body2" : "body1"}>
                          {team.sort_orders && team.sort_orders[index] !== undefined
                            ? (typeof info.precision === 'number'
                                ? team.sort_orders[index].toFixed(info.precision)
                                : team.sort_orders[index])
                            : 'N/A'}
                        </Typography>
                      </TableCell>
                    );
                  }
                  return null;
                })}
                {rankingData.extra_stats_info.map((info, index) => {
                  const column = `extra_stats_${index}`;
                  if (visibleColumns.includes(column)) {
                    return (
                      <TableCell key={`extra_${index}`}>
                        <Typography variant={isMobile ? "body2" : "body1"}>
                          {team.extra_stats && team.extra_stats[index] !== undefined
                            ? (typeof info.precision === 'number'
                                ? team.extra_stats[index].toFixed(info.precision)
                                : team.extra_stats[index])
                            : 'N/A'}
                        </Typography>
                      </TableCell>
                    );
                  }
                  return null;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RankingTable;